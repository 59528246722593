import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'

export type CalendarView = 'dayGridMonth' | 'resourceTimeGridWeek' | 'resourceTimeGridDay'

interface InitialState {
  calendarView: CalendarView
  loading: boolean
  showNurseAvailability: boolean
  loadedDateRange: {
    start: string
    end: string
  }
  visibleDateRange: {
    start: string
    end: string
  }
  loadedAppointments: Appointment[]
  visibleAppointments: Appointment[]
  filteredAppointments: Appointment[]
  todaysAppointments: Appointment[]
  roomAvailability: RoomAvailability[]
  nurseAvailability: NurseAvailability[]
}

const thisWeekStart = moment().utc().day(1).startOf('day').toISOString().split('T')[0]
const thisWeekEnd = moment().utc().day(7).startOf('day').toISOString().split('T')[0]

const initialState: InitialState = {
  loading: true,
  showNurseAvailability: true,
  calendarView: 'resourceTimeGridWeek',
  loadedDateRange: {
    start: thisWeekStart,
    end: thisWeekEnd,
  },
  visibleDateRange: {
    start: thisWeekStart,
    end: thisWeekEnd,
  },
  loadedAppointments: [],
  visibleAppointments: [],
  filteredAppointments: [],
  todaysAppointments: [],
  roomAvailability: [],
  nurseAvailability: [],
}

const calendarSlice = createSlice({
  name: 'Calendar',
  initialState: initialState,
  reducers: {
    setCalendarView: (state, action: PayloadAction<CalendarView>) => {
      state.calendarView = action.payload
    },
    setLoadedDateRange: (state, action: PayloadAction<{ start: string; end: string }>) => {
      state.loadedDateRange = {
        start: action.payload.start,
        end: action.payload.end,
      }
    },
    setVisibleDateRange: (state, action: PayloadAction<{ start: string; end: string }>) => {
      state.visibleDateRange = {
        start: action.payload.start,
        end: action.payload.end,
      }
    },
    setLoadedAppointments: (state, action: PayloadAction<Appointment[]>) => {
      state.loadedAppointments = action.payload
    },
    setVisibleAppointments: (state, action: PayloadAction<Appointment[]>) => {
      state.visibleAppointments = action.payload
    },
    setFilteredAppointments: (state, action: PayloadAction<Appointment[]>) => {
      state.filteredAppointments = action.payload
    },
    setTodaysAppointments: (state, action: PayloadAction<Appointment[]>) => {
      state.todaysAppointments = action.payload
    },
    addLoadedAppointment: (state, action: PayloadAction<Appointment>) => {
      state.loadedAppointments = [...state.loadedAppointments, action.payload]
    },
    removeLoadedAppointment: (state, action: PayloadAction<number>) => {
      const _loadedAppointment = state.loadedAppointments.find(appointment => appointment.id === action.payload)
      if (!_loadedAppointment) {
        return
      }
      const newLoadedAppointments = [...state.loadedAppointments]
      newLoadedAppointments.splice(state.loadedAppointments.indexOf(_loadedAppointment), 1)
      state.loadedAppointments = newLoadedAppointments
    },
    updateLoadedAppointment: (state, action: PayloadAction<Appointment>) => {
      const _loadedAppointment = state.loadedAppointments.find(_loaded => _loaded.id === action.payload.id)
      if (!_loadedAppointment) {
        return
      }
      state.loadedAppointments[state.loadedAppointments.indexOf(_loadedAppointment)] = action.payload
    },
    updateLoadedAppointments: (state, action: PayloadAction<Appointment[]>) => {
      const idsToReplace = [...action.payload].map(appointment => appointment.id).sort()
      const _loadedAppointmentsToReplace = [...state.loadedAppointments]
        .filter(loadedAppointment => idsToReplace.includes(loadedAppointment.id))
        .sort()
      if (_loadedAppointmentsToReplace.length) {
        _loadedAppointmentsToReplace.forEach((loadedAppointment, index) => {
          state.loadedAppointments[state.loadedAppointments.indexOf(loadedAppointment)] = action.payload[index]
        })
      }
    },
    setRoomAvailability: (state, action: PayloadAction<RoomAvailability[]>) => {
      state.roomAvailability = action.payload
    },
    updateRoomAvailability: (state, action: PayloadAction<RoomAvailability>) => {
      const date = new Date(action.payload.date).toDateString()
      const _availabilityDate = [...state.roomAvailability].find(roomAvailability => {
        return new Date(roomAvailability.date).toDateString() === date
      })
      if (_availabilityDate) {
        state.roomAvailability[state.roomAvailability.indexOf(_availabilityDate)].roomIds = action.payload.roomIds
      }
    },
    setNurseAvailability: (state, action: PayloadAction<NurseAvailability[]>) => {
      state.nurseAvailability = action.payload.map(item => {
        const userIds = item.userIds.sort()
        return {
          ...item,
          userIds,
        }
      })
    },
    updateNurseAvailability: (state, action: PayloadAction<NurseAvailability>) => {
      const date = new Date(action.payload.date).toDateString()
      const userIds = action.payload.userIds.map(id => Number(id)).sort()
      const _availabilityDate = [...state.nurseAvailability].find(nurseAvailability => {
        return new Date(nurseAvailability.date).toDateString() === date
      })
      if (!_availabilityDate) {
        state.nurseAvailability = [...state.nurseAvailability, { ...action.payload, userIds }]
      } else {
        state.nurseAvailability[state.nurseAvailability.indexOf(_availabilityDate)].userIds = userIds
      }
    },
  },
})

export const {
  setCalendarView,
  setLoadedDateRange,
  setVisibleDateRange,
  setLoadedAppointments,
  setVisibleAppointments,
  setFilteredAppointments,
  addLoadedAppointment,
  removeLoadedAppointment,
  updateLoadedAppointment,
  updateLoadedAppointments,
  setTodaysAppointments,
  setRoomAvailability,
  updateRoomAvailability,
  setNurseAvailability,
  updateNurseAvailability,
} = calendarSlice.actions

export default calendarSlice.reducer
