import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import { Field, useFormikContext } from 'formik'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import useData from 'hooks/useData'
import RemoveButton from 'components/RemoveButton'
import moment from 'moment'

type LocalDatesStateType = Map<number, string | null>

/**
 * The table component inside the Travel tab
 */
const TravelTable = ({ tripIndex, isEditing, handleAdd, handleChange, handleRemove }) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<Patient>()
  const data = useData()

  const { accommodations, destinations, durations } = {
    accommodations: data.accommodations.all(),
    destinations: data.destinations.all(),
    durations: data.journeyDurations.all(),
  }

  interface TableData {
    destination: string
    accommodationStyle: string
    arrivalDate: Date
    returnDate: Date | null
    duration: string | null
  }

  const tableData: TableData[] = useMemo(
    () =>
      values.trips[tripIndex].journeys.map(journey => ({
        destination: data.destinations.one(journey.destination.id)?.title || '',
        accommodationStyle: data.accommodations.one(journey.accommodation.id)?.title || '',
        arrivalDate: new Date(journey.arrivalDate),
        returnDate: journey.returnDate ? new Date(journey.returnDate) : null,
        duration: journey.duration ? data.journeyDurations.one(journey.duration.id)?.title || null : null
      })),
    [tripIndex, values.trips[tripIndex]]
  )

  const columnHelper = createColumnHelper<TableData>()

  const handleUseReturnDate = (useDate, tripIndex, journeyIndex) => {
      setFieldValue(`trips[${tripIndex}].journeys[${journeyIndex}].duration`, null)
      setFieldValue(`trips[${tripIndex}].journeys[${journeyIndex}].returnDate`, useDate ? moment().format('YYYY-MM-DD') : null)
  }

  const initialArrivalDates = useMemo<readonly ( readonly [number, string | null] )[]>(() => {
    return       values.trips[tripIndex].journeys.map((journey, index) => [index, journey.arrivalDate])
  }, [tripIndex, values])

  const initialReturnDates = useMemo<readonly ( readonly [number, string | null] )[]>(() => {
    return       values.trips[tripIndex].journeys.map((journey, index) => [index, journey.returnDate])
  }, [tripIndex, values])

  const [arrivalDates, setArrivalDates] = useState<LocalDatesStateType>(new Map(initialArrivalDates))
  const [returnDates, setReturnDates] = useState<LocalDatesStateType>(new Map(initialReturnDates))

  function setDate({ rowIndex, date }: {rowIndex: number, date: string}, currentDates: LocalDatesStateType, setDates: Dispatch<SetStateAction<LocalDatesStateType>>) {
    setDates(new Map(currentDates.set(rowIndex, date)))
  }
  
  const tableColumns = useMemo(
    () => [
      columnHelper.accessor('destination', {
        header: 'Destination',
        cell: info => (
          <Field
            disabled={!isEditing || isSubmitting}
            as={Form.Select}
            name={`trips[${tripIndex}].journeys[${info.row.index}].destination.id`}
          >
            <option value="default" selected disabled>
              Please select
            </option>
            {destinations.map(destination => (
              <option key={destination.id} value={destination.id}>
                {destination.title}
              </option>
            ))}
          </Field>
        ),
      }),
      columnHelper.accessor('accommodationStyle', {
        header: 'Accommodation style',
        cell: info => (
          <Field
            disabled={!isEditing || isSubmitting}
            as={Form.Select}
            name={`trips[${tripIndex}].journeys[${info.row.index}].accommodation.id`}
          >
            <option value="default" selected disabled>
              Please select
            </option>
            {accommodations.map(accommodation => (
              <option key={accommodation.id} value={accommodation.id}>
                {accommodation.title}
              </option>
            ))}
          </Field>
        ),
      }),
      columnHelper.accessor('arrivalDate', {
        header: 'Arrival date',
        cell: info => (
          <Field
            name={`trips[${tripIndex}].journeys[${info.row.index}].arrivalDate`}
          >
            {({field, form, meta}) => {
              const date = arrivalDates.get(info.row.index)
              const dateStringified = date ? date.toString() : new Date().toString()
              return (
              <Form.Control type="date" disabled={!isEditing || isSubmitting} value={dateStringified} onChange={(event) => setDate({ rowIndex: info.row.index, date: event.target.value }, arrivalDates, setArrivalDates)} onBlur={(event) => {
                setFieldValue(`trips[${tripIndex}].journeys[${info.row.index}].arrivalDate`, event.target.value)}}></Form.Control>)}}
          </Field>
        ),
      }),
      columnHelper.accessor('duration', {
        header: 'Length of Stay',
        cell: info => (
          <>
            <Field
              disabled={!isEditing || isSubmitting}
              className={!!!values.trips[tripIndex].journeys[info.row.index].returnDate ? '' : 'd-none'}
              as={Form.Select}
              name={`trips[${tripIndex}].journeys[${info.row.index}].duration.id`}
            >
              <option value="default" selected disabled>
                Please select
              </option>
              {durations.map(duration => (
                <option key={duration.id} value={duration.id}>
                  {duration.title}
                </option>
              ))}
            </Field>
            <Button
              variant="secondary"
              className={!!!values.trips[tripIndex].journeys[info.row.index].returnDate ? 'd-none' : ''}
              disabled={!isEditing || isSubmitting}
              onClick={() => handleUseReturnDate(false, tripIndex, info.row.index)}
              >
                Use Length of Stay
            </Button>
          </>
        ),
      }),
      columnHelper.accessor('returnDate', {
        header: 'Return date',
        cell: info => (
          !!values.trips[tripIndex].journeys[info.row.index].returnDate ? (
            <Field
            name={`trips[${tripIndex}].journeys[${info.row.index}].returnDate`}
          >
            {({field, form, meta}) => {
              const date = returnDates.get(info.row.index)
              const dateStringified = date ? date.toString() : new Date().toString()
              return (
              <Form.Control type="date" disabled={!isEditing || isSubmitting} value={dateStringified} onChange={(event) => setDate({ rowIndex: info.row.index, date: event.target.value }, returnDates, setReturnDates)} onBlur={(event) => {
                setFieldValue(`trips[${tripIndex}].journeys[${info.row.index}].returnDate`, event.target.value)}}></Form.Control>)}}
          </Field>
          ) : (
            <Button
              variant="secondary"
              className={!!!values.trips[tripIndex].journeys[info.row.index].returnDate ? '' : 'd-none'}
              disabled={!isEditing || isSubmitting}
              onClick={() => handleUseReturnDate(true, tripIndex, info.row.index)}
              >
                Use Return Date
            </Button>
          )
        ),
      }),
      columnHelper.display({
        id: 'remove',
        header: '',
        cell: info => (
          <RemoveButton disabled={!isEditing || isSubmitting} onClick={() => handleRemove(info.row.index)} />
        ),
      }),
    ],
    [!isEditing || isSubmitting, isEditing, tripIndex, values.trips[tripIndex]]
  )

  return (
    <div>
      <FunctionalTable data={tableData} columns={tableColumns} />
    </div>
  )
}

export default TravelTable
