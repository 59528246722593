import { add15MinsToDate, getNextFreeSlotFromDate } from 'helpers/date'
import useApi from 'hooks/useApi'
import { loadAvailability } from 'hooks/useAvailability'
import useData from 'hooks/useData'
import moment from 'moment'
import { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { openNewAppointment, setNewAppointmentDate, setNewAppointmentRoom } from 'redux/stateSlice'

const NewAppointmentButton = () => {
  const dispatch = useDispatch()
  
  const [isLoading, setIsLoading] = useState(false)

  const initialDate = new Date()

  const api = useApi()
  const data = useData()

  let availableSlotRoomId: string | null = null

  const handleClick = async () => {
    // Get rooms outside of the loop since they don't depend on any iterated value
    const rooms = data.rooms.all()
    
    let bestDate = initialDate

    let isBestDatePossible = false

    setIsLoading(true)

    // Find availability
    const bestDateMoment = moment(getNextFreeSlotFromDate(bestDate))
    const day = bestDateMoment.format('YYYY-MM-DD')
    const dayEnd = bestDateMoment.clone().add(1, 'day').format('YYYY-MM-DD')

    const appointments = await api.appointments.range(day, dayEnd) // all appointments for the day
    const roomAvailabilities = await api.roomAvailability.range(day, day) // all available rooms for the day

    if (appointments.errors || roomAvailabilities.errors) {
      setIsLoading(false)
      dispatch(openNewAppointment())
      return
    }

    let availability = await loadAvailability(bestDateMoment, appointments.data, roomAvailabilities.data, rooms)
    
    while (!isBestDatePossible) {
      const bestDateAsSlot = getNextFreeSlotFromDate(bestDate).toISOString()

      isBestDatePossible = false

      availabilityCheckLoop:
      for (const room of availability) {
        for (const slot of room.slots) {
          if (slot.durations.length < 1) {
            continue
          }
          if (bestDateAsSlot === slot.date.toISOString()) {
            availableSlotRoomId = room.roomId.toString()
            
            isBestDatePossible = true
            break availabilityCheckLoop
          }
        }
      }

      if (!isBestDatePossible) {
        const triedDate = bestDate
        bestDate = add15MinsToDate(bestDate)

        if (triedDate.getDay() !== bestDate.getDay()) {
          const bestDateMoment = moment(bestDateAsSlot)
          // need to refetch latest availability
          const day = bestDateMoment.format('YYYY-MM-DD')
          const dayEnd = bestDateMoment.clone().add(1, 'day').format('YYYY-MM-DD')
      
          const appointments = await api.appointments.range(day, dayEnd) // all appointments for the day
          const roomAvailabilities = await api.roomAvailability.range(day, day) // all available rooms for the day
      
          if (appointments.errors || roomAvailabilities.errors) {
            setIsLoading(false)
            dispatch(openNewAppointment())
            return
          }
      
          availability = await loadAvailability(bestDateMoment, appointments.data, roomAvailabilities.data, rooms)
        }
      }
    }

    setIsLoading(false)

    dispatch(setNewAppointmentDate(getNextFreeSlotFromDate(bestDate).toISOString()))
    dispatch(setNewAppointmentRoom(availableSlotRoomId))

    dispatch(openNewAppointment())
  }

  return (
  <Button onClick={handleClick} disabled={isLoading}>
    {isLoading ? (
      <Spinner animation="border" role="status" size="sm">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    ) : <>NEW APPOINTMENT</>}
    
  </Button>
  )
}

export default NewAppointmentButton
