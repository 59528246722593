import { Plus } from 'components/Icons'
import { FieldArray, useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import PrescriptionsTable from '../tables/PrescriptionsTable'
import { PatientTabProps } from '../PatientInfo'
import useAuth from 'hooks/useAuth'
import moment from 'moment'
import useHelpers from 'hooks/useHelpers'

const PrescriptionsTab: React.FC<PatientTabProps> = ({ isEditing }) => {
  const { handleChange, isSubmitting } = useFormikContext<Patient>()
  const { user } = useAuth()
  const helpers = useHelpers()

  const newPrescription = {
    dateAdministered: moment().format('YYYY-MM-DD'),
    quantity: '1',
    batchNumber: '',
    drug: {
      id: null,
    },
    administerer: {
      id: user?.id && helpers.nurses.isNurse(user.id) ? user.id : null,
    },
  }

  return (
    <div className="w-100">
      <FieldArray name="malariaMedicines">
        {arrayHelpers => {
          return (
            <>
              <PrescriptionsTable
                isEditing={isEditing}
                handleRemove={arrayHelpers.remove}
                handleChange={handleChange}
              />
              <div className="d-flex justify-content-center align-item-center py-4">
                <Button
                  size="sm"
                  disabled={!isEditing || isSubmitting}
                  onClick={() => {
                    arrayHelpers.push(newPrescription)
                  }}
                >
                  Add medicine <Plus />
                </Button>
              </div>
            </>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default PrescriptionsTab
