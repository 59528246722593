import React from 'react'
import { DatesSetArg, EventClickArg, EventContentArg, ClassNamesGenerator } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import momentPlugin from '@fullcalendar/moment'
import timegridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import Bootstrap5Plugin from '@fullcalendar/bootstrap5'
import { Form, Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setNewAppointmentDate, setNewAppointmentRoom, openNewAppointment, setEditAppointmentId } from 'redux/stateSlice'
import { CalendarView, setCalendarView, updateRoomAvailability } from 'redux/calendarSlice'
import { RootState } from 'redux/store'
import { useMemo, useState } from 'react'
import useData from 'hooks/useData'
import useHelpers from 'hooks/useHelpers'
import 'scss/components/event-block.scss'
import moment from 'moment'
import useApi from 'hooks/useApi'
import EventBlock from './EventBlock'
import useModal from 'hooks/useModal'
import useToast from 'hooks/useToast'

const formatAppointments = (appointments: Appointment[]) => {
  return appointments.map(appointment => ({
    id: appointment.id.toString(),
    start: appointment.startDate,
    end: appointment.endDate,
    title: appointment.title,
    statusId: appointment.status.id,
    typeId: appointment.type.id,
    roomId: appointment.room.id,
    patients: appointment.patients ? appointment.patients : [],
    resourceId: appointment.room.id.toString(),
  }))
}

const formatNurseAvailability = (availabilityItems: NurseAvailability[]) => {
  return availabilityItems
    .map(day => {
      return day.userIds.map(userId => ({
        start: day.date,
        allDay: true,
        title: 'Staff member', // This isn't the actual title shown in the UI, we need to get the name of the nurse
        // So this is done in EventBlock.tsx instead
        resourceId: '1',
        userId,
      }))
    })
    .flat()
}

export type EventExtendedProps = Omit<ReturnType<typeof formatAppointments>[number], keyof EventContentArg['event']>
export type NurseExtendedProps = Omit<
  ReturnType<typeof formatNurseAvailability>[number],
  keyof EventContentArg['event']
>

interface CalendarWidgetProps {
  nurseAvailability: NurseAvailability[]
  appointments: Appointment[]
  handleChangeDateRange: (newStart: string, newEnd: string) => void
}

/**
 * The main calendar widget shown on the index page
 */
const CalendarWidget: React.FC<CalendarWidgetProps> = ({ nurseAvailability, appointments, handleChangeDateRange }) => {
  const dispatch = useDispatch()
  const data = useData()
  const helpers = useHelpers()
  const api = useApi()
  const toast = useToast()
  const { openModal: openNurseAvailabilityModal } = useModal('NURSE_AVAILABILITY')

  const [isUpdatingAvailability, setIsUpdatingAvailability] = useState(false)

  // useMemo is needed because of a fullcalendar/react bug
  // @see https://github.com/fullcalendar/fullcalendar-react/issues/123
  const HIDDEN_DAYS = useMemo(() => [0], [])

  const { currentCalendarView, showNurseAvailability } = useSelector((state: RootState) => ({
    showNurseAvailability: state.calendar.showNurseAvailability,
    currentCalendarView: state.calendar.calendarView,
  }))

  const rooms = data.rooms.all()

  const resources = useMemo(() => {
    return rooms.map(room => ({
      id: room.id.toString(),
      title: room.title,
    }))
  }, [])

  const _appointments = useMemo(() => {
    return formatAppointments(appointments)
  }, [appointments])

  const _nurseAvailability = useMemo(() => {
    return formatNurseAvailability(nurseAvailability)
  }, [nurseAvailability])

  // ------------- Calendar Hooks -------------

  const handleViewChange = (viewChangeEvent: DatesSetArg) => {
    const { startStr, endStr } = viewChangeEvent
    const { type } = viewChangeEvent.view
    const newView = type as CalendarView
    newView !== currentCalendarView && dispatch(setCalendarView(newView))
    handleChangeDateRange(startStr, endStr)
    return ''
  }

  const handleDateClick = (data: DateClickArg) => {
    if (data.resource) {
      dispatch(setNewAppointmentRoom(data.resource._resource.id))
    }
    if (data.allDay) {
      // Open nurse availability modal from date
      openNurseAvailabilityModal({
        date: data.dateStr,
      })
    } else {
      const roomId = data.resource?.id as string
      if (
        (roomId === '2' && !helpers.roomAvailability.hasSecondRoomAvailable(data.date)) ||
        data.view.type === 'dayGridMonth'
      ) {
        return
      }
      const clickedDate = data.date
      dispatch(setNewAppointmentDate(clickedDate.toISOString()))
      dispatch(openNewAppointment())
    }
  }

  const handleEventClick = (data: EventClickArg) => {
    if (data.event.allDay) {
      // Event is a staff event
      openNurseAvailabilityModal({
        date: data.event.startStr,
      })
    } else {
      // Event is an appointment
      dispatch(setEditAppointmentId(parseInt(data.event.id)))
    }
  }

  const handleEventClassNames: ClassNamesGenerator<EventContentArg> = event => {
    if (event.event.allDay) return ['staff-event']
    const { typeId, statusId } = event.event.extendedProps as EventExtendedProps
    const typeTitle = data.appointmentTypes.one(typeId)?.value
    const statusTitle = data.appointmentStatuses.one(statusId)?.value
    return [`type:${typeTitle}`, `status:${statusTitle}`]
  }

  // ------------------------------------------

  return (
    <div className="calendar-widget">
      <FullCalendar
        schedulerLicenseKey="0516433130-fcs-1677664578"
        plugins={[
          momentPlugin,
          dayGridPlugin,
          timegridPlugin,
          resourceTimeGridPlugin,
          interactionPlugin,
          Bootstrap5Plugin,
        ]}
        eventInteractive={false}
        initialView={currentCalendarView}
        resources={resources}
        events={[..._appointments, ..._nurseAvailability]}
        resourceLabelContent={data => {
          // Hooking into the headers for resources
          if (data.view.type !== 'dayGridMonth') {
            const { id: roomId, title: roomTitle } = data.resource
            const date = data.date as Date
            if (roomId === '2') {
              const isAvailable = helpers.roomAvailability.hasSecondRoomAvailable(date)
              const handleSubmitAvailability = async () => {
                setIsUpdatingAvailability(true)
                const dateFormatted = moment(date).format('YYYY-MM-DD')
                const body: RoomAvailability = {
                  date: dateFormatted,
                  roomIds: [1],
                }
                if (!isAvailable) {
                  body.roomIds.push(2)
                }
                const availabilityRequest = await api.roomAvailability.update(body)
                if (availabilityRequest.data) {
                  dispatch(updateRoomAvailability(availabilityRequest.data))
                } else {
                  toast.error({
                    title: 'Error updating room availability',
                    text: availabilityRequest.errors[0].body,
                    timestamp: true,
                  })
                }
                setIsUpdatingAvailability(false)
              }
              return (
                <div className="d-flex flex-wrap gap-2 align-items-center justify-content-center">
                  <span className="whitespace-nowrap fw-normal">{roomTitle}</span>
                  {isUpdatingAvailability ? (
                    <Spinner size="sm" />
                  ) : (
                    <Form.Check
                      type="checkbox"
                      id={`${date.toDateString().replaceAll(' ', '-')}:${roomId}`}
                      checked={isAvailable}
                      onChange={handleSubmitAvailability}
                    />
                  )}
                </div>
              )
            } else {
              return (
                <div>
                  <span className="whitespace-nowrap fw-normal">{roomTitle}</span>
                </div>
              )
            }
          }
        }}
        dayCellClassNames={data => {
          // Hooking into the columns for resource columns
          if (data.view.type !== 'dayGridMonth') {
            const { id: roomId } = data.resource
            const date = data.date as Date
            if (roomId === '2' && !helpers.roomAvailability.hasSecondRoomAvailable(date)) {
              return 'room-unavailable'
            }
          }
          return ''
        }}
        datesAboveResources={true}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        datesSet={handleViewChange}
        eventContent={event => <EventBlock event={event} />}
        progressiveEventRendering={true}
        eventClassNames={handleEventClassNames}
        nowIndicator={true}
        eventOrder="roomId"
        eventOrderStrict={true}
        slotEventOverlap={false}
        eventMaxStack={2}
        views={{
          dayGridMonth: {
            resources: [],
            allDaySlot: false,
          },
          resourceTimeGridDay: {
            dayHeaderFormat: 'dddd DD MMMM', // this is display: none as it duplicates the header
            titleFormat: 'dddd MMMM DD, YYYY',
            allDaySlot: showNurseAvailability,
          },
          resourceTimeGridWeek: {
            allDaySlot: showNurseAvailability,
            dayHeaderFormat: 'ddd DD/MM',
          },
        }}
        lazyFetching={true}
        navLinks={true}
        fixedWeekCount={false}
        headerToolbar={{
          left: 'title',
          right: 'today,dayGridMonth,resourceTimeGridWeek,resourceTimeGridDay prev,next',
        }}
        themeSystem="bootstrap5"
        handleWindowResize={true}
        eventDisplay={'block'}
        height="100%"
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        }}
        allDayText={'Staff'}
        firstDay={1}
        slotMinTime="08:00:00"
        slotMaxTime={
          // currentCalendarView === 'resourceTimeGridDay' && currentDate.getDay() !== 4 ? '17:00:00' : '21:00:00'
          '21:00:00'
        }
        slotDuration="00:15:00"
        hiddenDays={HIDDEN_DAYS}
        businessHours={[
          {
            daysOfWeek: [1, 2, 3, 4, 5, 6],
            startTime: '08:00',
            endTime: '12:30',
          },
          {
            daysOfWeek: [1, 2, 3, 4, 5, 6],
            startTime: '13:15',
            endTime: '21:00',
          },
          // Override afternoon period for Saturdays
          {
            daysOfWeek: [6],
            startTime: '13:00',
            endTime: '21:00',
          },
        ]}
        slotLabelInterval="01:00:00"
        slotLabelFormat={{
          hour: 'numeric',
          minute: '2-digit',
          hour12: false,
        }}
      />
    </div>
  )
}

export default CalendarWidget
