type AppError = {
  code: number
  body: string
  source: string
}

const useFetch = () => {
  const controller = new AbortController()
  const signal = controller.signal

  const cancelAll = () => {
    controller.abort()
  }

  // TODO: handle array params
  const serializeParams = <T extends {}>(params: T) => {
    let paramArray: string[] = []
    Object.entries(params).forEach(([key, value]: [key: string, value: any]) => {
      if (params[key] && value) {
        if (Array.isArray(value)) {
          let valueArray: string[] = []
          value.forEach(arrayItem => {
            valueArray.push(`${key}[]=${arrayItem.toString()}`)
          })
          paramArray = [...paramArray, ...valueArray]
        } else {
          paramArray.push(`${key}=${value.toString()}`)
        }
      }
    })
    return [...paramArray].join('&')
  }

  // TODO: handle errors somehow in the app
  const throwError = (error: AppError) => {
    // throw new Error(error.body)
  }

  const handleResponse = async <TData>(response: Response) => {
    // Network error
    if (!response.ok) {
      const error = {
        code: response.status,
        body: response.statusText,
        source: response.url,
      }
      throwError(error)
      return {
        errors: [error],
      }
    }

    // Successfully connected to backend
    const responseBody = await response.json()

    // Server errors
    if (!responseBody.meta.success) {
      const errors = [...responseBody.errors].map(error => ({
        code: error.status,
        body: error.detail,
        source: error.source,
      }))
      errors.forEach(error => throwError(error))
      return {
        errors: errors,
      }
    }

    return {
      data: responseBody.data as TData,
    }
  }

  /**
   * Perform a GET Request
   */
  const getRequest = async <TData>(endpoint: string, params?) => {
    const _params = params ? `?${serializeParams(params)}` : ''

    const response = await fetch(`${process.env.REACT_APP_CMS_URL}/${endpoint}${_params}`, {
      signal: signal,
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })

    return handleResponse<TData>(response)
  }

  /**
   * Perform a POST Request
   */
  const postRequest = async <TData>(endpoint: string, body, params?, contentType: boolean = true) => {
    const _params = params ? `${serializeParams(params)}` : ''

    const headers = {
      Accept: 'application/json',
    }
    if (contentType) headers['Content-Type'] = 'application/json'

    const response = await fetch(`${process.env.REACT_APP_CMS_URL}/${endpoint}${_params}`, {
      signal: signal,
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: headers,
      body: body,
    })

    return handleResponse<TData>(response)
  }

  /**
   * Perform and put request
   */
  const putRequest = async <TData>(endpoint: string, body, params?) => {
    const _params = params ? `${serializeParams(params)}` : ''

    const response = await fetch(`${process.env.REACT_APP_CMS_URL}/${endpoint}${_params}`, {
      signal: signal,
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: body,
    })

    return handleResponse<TData>(response)
  }

  /**
   * Perform a DELETE Request
   */
  const deleteRequest = async <TData>(endpoint: string) => {
    const response = await fetch(`${process.env.REACT_APP_CMS_URL}/${endpoint}`, {
      signal: signal,
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
    })

    return handleResponse<TData>(response)
  }

  return {
    getRequest,
    postRequest,
    deleteRequest,
    putRequest,
    cancelAll,
  }
}

export default useFetch
