import { useFormikContext } from "formik";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { AppointmentSchemaType } from "schemas/appointment-schema";

const NewPatientConfirmationModal = ({hasAcknowledgedConfirmation, setHasAcknowledgedConfirmation} : {hasAcknowledgedConfirmation: boolean | null; setHasAcknowledgedConfirmation: React.Dispatch<React.SetStateAction<boolean | null>>}) => {
    const { isSubmitting, handleSubmit } = useFormikContext<AppointmentSchemaType>()
    useEffect(() => {
        if (hasAcknowledgedConfirmation) {
            handleSubmit()
            setHasAcknowledgedConfirmation(null)
        }
    }, [hasAcknowledgedConfirmation])
    return (
        <Modal show={hasAcknowledgedConfirmation === false} onHide={() => setHasAcknowledgedConfirmation(null)}>
            <Modal.Header closeButton>
                <Modal.Title>Please confirm...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You acknowledge that this will send a new patient registration email for a patient that is travelling!</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" disabled={isSubmitting} onClick={() => setHasAcknowledgedConfirmation(null)}>Go back</Button>
                <Button variant="success" onClick={() => {
                    setHasAcknowledgedConfirmation(true)                  
                }}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewPatientConfirmationModal