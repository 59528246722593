import React, { useState } from 'react'
import { Button, Table, Form, Spinner, Badge } from 'react-bootstrap'
import useApi from 'hooks/useApi'
import { Upload, Tick, Cross } from './Icons'
import useToast from 'hooks/useToast'
import { useFormikContext } from 'formik'
import RemoveButton from './RemoveButton'
import 'scss/components/file-uploader.scss'

const FileUploader = ({ patientId, onSuccess }: { patientId: number; onSuccess: () => Promise<void> }) => {
  const api = useApi()
  const toast = useToast()

  const { initialValues } = useFormikContext<Patient>()

  const MAX_FILE_SIZE = 8000000 // 8MB :: 8 million bytes

  const [isDraggingInside, setIsDraggingInside] = useState(false)

  const [isProcessing, setIsProcessing] = useState(false)

  const [currentFiles, setCurrentFiles] = useState<
    {
      title: string
      file: File
      valid: boolean
      isUploading: boolean
      failed: boolean
      success: boolean
    }[]
  >([])

  const handleDragEnter = e => {
    e.stopPropagation()
    e.preventDefault()
    setIsDraggingInside(true)
  }
  const handleDragLeave = e => {
    e.stopPropagation()
    e.preventDefault()
    setIsDraggingInside(false)
  }
  const handleDragOver = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  // When the user actually drops files into the zone
  const handleDrop: React.DragEventHandler<HTMLDivElement> = e => {
    e.stopPropagation()
    e.preventDefault()
    const dt = e.dataTransfer
    const files = dt.files
    setIsDraggingInside(false)
    handleFiles(files)
  }

  // When the user adds files manually
  const handleFilesChange: React.ChangeEventHandler<HTMLInputElement> = changeEvent => {
    const { files } = changeEvent.target
    files !== null && handleFiles(files)
  }

  const handleFiles = (files: FileList) => {
    const _files = Array.from(files)

    // ... Validate file sizes
    // TODO: validate file types, but this can be handled on the input 'accept' attribute
    _files.forEach(file => {
      let valid = true
      // Check if file already exists in array
      if (
        currentFiles &&
        currentFiles.filter(({ file: currentFile }) => {
          return (
            currentFile.size === file.size &&
            currentFile.lastModified === file.lastModified &&
            currentFile.type === file.type &&
            currentFile.webkitRelativePath === file.webkitRelativePath
          )
        }).length <= 0
      ) {
        if (file.size >= MAX_FILE_SIZE) {
          valid = false
        }
        setCurrentFiles(prev => {
          return [
            ...prev,
            {
              title: file.name.replace(/\.[a-zA-Z0-9]+/i, ''),
              file,
              valid,
              isUploading: false,
              failed: false,
              success: false,
            },
          ]
        })
      }
    })
  }

  const handleRemoveFile = (index: number) => {
    setCurrentFiles(prev => {
      const newFiles = [...prev]
      newFiles.splice(index, 1)
      return newFiles
    })
  }

  const handleUploadOne = async (index: number) => {
    currentFiles[index].isUploading = true
    const fileObject = currentFiles[index]
    const formData = new FormData()
    formData.append('document', fileObject.file)
    formData.append('title', fileObject.title)
    formData.append('ownerId', patientId.toString())
    const upload = await api.documents.upload(formData)
    if (upload.errors) {
      currentFiles[index].failed = true
      throw upload.errors[0].body
    }
    currentFiles[index].success = true
    currentFiles[index].isUploading = false
  }

  const handleUploadAll = async () => {
    try {
      setIsProcessing(true)
      await Promise.all(
        [...currentFiles].map((fileObject, index) => {
          if (fileObject.valid && !fileObject.success) return handleUploadOne(index)
          else return true
        })
      )
      await onSuccess()
      toast.success({
        title: 'Upload successful',
        text: `Successfully uploaded files for ${initialValues.firstName} ${initialValues.lastName}`,
      })
      setCurrentFiles(prev => {
        return [...prev].filter(fileObject => fileObject.failed === true)
      })
    } catch (e) {
      toast.error({
        title: 'Error uploading files',
        text: 'There was an error with one of your files. ' + e,
      })
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <div className="file-uploader-wrapper">
      <div
        className={`file-uploader-input d-flex flex-column gap-5 ${isDraggingInside ? 'active' : ''}`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {currentFiles !== undefined && currentFiles.length ? (
          <div className="file-uploader-files">
            <Table>
              <thead className="bg-secondary-accent">
                <tr>
                  <th>Title</th>
                  <th>File</th>
                  <th>Size</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {currentFiles.map((fileObject, index) => {
                  const { file, valid } = fileObject
                  let size = file.size
                  let sizeString = 'bytes'
                  if (file.size >= 1000000) {
                    size = size / 1000000
                    sizeString = 'MB'
                  } else if (file.size >= 1000) {
                    size = size / 1000
                    sizeString = 'kB'
                  }
                  return (
                    <tr key={index} className={`${valid ? '' : 'table-danger'}`}>
                      <td>
                        <Form.Control
                          id={fileObject.file.webkitRelativePath}
                          value={currentFiles[index].title}
                          disabled={currentFiles[index].success || !currentFiles[index].valid}
                          onChange={e =>
                            setCurrentFiles(prev => {
                              const newFiles = [...prev]
                              newFiles[index].title = e.target.value
                              return [...newFiles]
                            })
                          }
                        />
                      </td>
                      <td>{file.name}</td>
                      <td>
                        {parseFloat(size.toFixed(1)).toLocaleString()} {sizeString}
                      </td>
                      <td>
                        {currentFiles[index].isUploading ? (
                          <Spinner size="sm" />
                        ) : currentFiles[index].success ? (
                          <Badge bg="success">
                            Upload successful <Tick />
                          </Badge>
                        ) : currentFiles[index].failed ? (
                          <Badge bg="danger">
                            Upload failed <Cross />
                          </Badge>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>
                        <RemoveButton onClick={e => handleRemoveFile(index)} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
            <div className="d-flex align-items-center justify-content-center mt-5">
              <Button
                className="text-white"
                variant="danger"
                disabled={isProcessing || currentFiles.filter(fileObject => fileObject.valid === false).length > 0}
                onClick={handleUploadAll}
              >
                {isProcessing ? (
                  <>
                    <Spinner size="sm" />
                    Uploading...
                  </>
                ) : (
                  'Upload'
                )}
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="instructions gap-3">
          Drag and drop files or upload manually
          <Button type="button" as="label" htmlFor="file-uploader-input" className="d-flex align-items-center gap-2">
            Browse computer
            <Upload />
          </Button>
          <input
            aria-hidden={true}
            type="file"
            id="file-uploader-input"
            multiple
            onChange={handleFilesChange}
            className="d-inline-flex align-items-center gap-2"
          />
        </div>
      </div>
    </div>
  )
}

export default FileUploader
