import TextArea from 'components/TextArea'
import { Field, useFormikContext } from 'formik'
import { Col, Collapse, FloatingLabel, Form, Row } from 'react-bootstrap'
import FormSection from '../FormSection'
import { PatientTabProps } from '../PatientInfo'
import { useEffect } from 'react'

const MedsTab: React.FC<PatientTabProps> = ({ isEditing }) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<Patient>()

  const checklist = [
    { title: 'Respiratory illness e.g. Asthma?', key: 'respiratory_illness' },
    { title: 'Heart disease or high blood pressure?', key: 'heart_disease' },
    { title: 'Diabetes or epilepsy?', key: 'diabetes_or_epilepsy' },
    { title: 'Gastro-intestinal illness, liver or kidney disease?', key: 'gastro_liver_or_kidney' },
    { title: 'Immunosuppression e.g. thymus disorder, cancer treatment or HIV / AIDS?', key: 'immunosuppression' },
    { title: 'Mental health (psychiatric) illness e.g. depression, eating disorder?', key: 'mental_health' },
    { title: 'Other', key: 'other' },
    { title: 'Is patient breastfeeding, pregnant, or planning to be?', key: 'breast_feeding_pregnancy' },
    { title: 'Allergies?', key: 'allergies' },
    { title: 'Is patient taking any medication, prescribed or other?', key: 'medication' },
    // { title: 'Radiotherapy, chemotherapy or steroid treatment?', key: 'childhood_vaccines' },
  ]

  return (
    <div className="w-100">
      <Row>
        <Col xs={12}>
          <FormSection title="Medical History">
            <ol className="d-flex flex-column gap-2 ps-3">
              {checklist.map(item => {
                return (
                  <li key={item.key}>
                    <hr />
                    <div className="d-flex flex-column" key={item.key}>
                      <div className="d-flex justify-content-between align-items-center">
                        <span>{item.title}</span>
                        <div className="d-flex gap-3">
                          {/* <span className="mx-2">{values.medicalHistory[item.key]}</span> */}
                          <Field
                            className="mb-3"
                            disabled={!isEditing || isSubmitting}
                            as={Form.Check}
                            type="radio"
                            size="lg"
                            label={'Yes'}
                            checked={values.medicalHistory[item.key] == 1}
                            id={`${item.key}-off`}
                            value={1}
                            name={`medicalHistory[${item.key}]`}
                          />
                          <Field
                            className="mb-3"
                            disabled={!isEditing || isSubmitting}
                            as={Form.Check}
                            type="radio"
                            size="lg"
                            label={'No'}
                            checked={values.medicalHistory[item.key] == 0}
                            id={`${item.key}-on`}
                            value={0}
                            name={`medicalHistory[${item.key}]`}
                          />
                        </div>
                      </div>
                      <Collapse in={!!parseInt(values.medicalHistory[item.key])}>
                        <FloatingLabel label="More Information">
                          <Field
                            disabled={!isEditing || isSubmitting}
                            as={TextArea}
                            rows={4}
                            name={`medicalHistory[${item.key}_detail]`}
                          />
                          <div className="mb-4" /> {/* needed for spacing purposes */}
                        </FloatingLabel>
                      </Collapse>
                    </div>
                  </li>
                )
              })}
            </ol>
          </FormSection>
        </Col>
      </Row>
    </div>
  )
}

export default MedsTab
