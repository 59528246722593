import { Button } from 'react-bootstrap'
import { Trash } from './Icons'

const RemoveButton = ({ ...props }) => {
  return (
    <Button
      {...props}
      variant="danger"
      className="d-flex align-items-center justify-content-center text-white px-2 py-2"
    >
      <Trash size={20} />
    </Button>
  )
}

export default RemoveButton
