import { Nav } from 'react-bootstrap'
import { Filter, Today } from 'components/Icons'
import { createContext, useState, useEffect, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'
import SidebarLink from './SidebarLink'
import FilterPanel from './panels/FilterPanel'
import TodaysAppointmentsPanel from './panels/TodaysAppointmentsPanel'
import { AnimatePresence, easeInOut, motion } from 'framer-motion'
import 'scss/components/icon-sidebar.scss'
import { RootState } from 'redux/store'

const tabs = {
  filter: FilterPanel,
  appointments: TodaysAppointmentsPanel,
}

export type SidebarTabId = keyof typeof tabs

const useSidebarHelpers = () => {
  const [currentTab, setCurrentTab] = useState<SidebarTabId | null>(null)
  return {
    currentTab,
    setCurrentTab,
  }
}

type SidebarContext = ReturnType<typeof useSidebarHelpers>

const sidebarContext = createContext<SidebarContext>({
  currentTab: useSidebarHelpers['currentTab'],
  setCurrentTab: useSidebarHelpers['setCurrentTab'],
})

export const useSidebarContext = () => useContext(sidebarContext)

const SidebarTab = ({ id, panel }: { id: SidebarTabId; panel: any }) => {
  const Component = panel
  return (
    <motion.div
      style={{
        position: 'absolute',
        top: '0',
        left: '100%',
        height: '100%',
        zIndex: '-1',
      }}
      className="border-end"
      id={id}
      key={id}
      initial={{ opacity: 0 }}
      animate={{
        // x: 0,
        opacity: 1,
        transition: {
          ease: easeInOut,
        },
      }}
      exit={{
        opacity: 0,
        // x: '-100%',
        transition: {
          ease: easeInOut,
        },
      }}
    >
      <Component />
    </motion.div>
  )
}

const CalendarSidebar = () => {
  const { currentTab, setCurrentTab } = useSidebarHelpers()
  const sidebarRef = useRef<HTMLDivElement>(null)

  const { newAppointmentOpen, editAppointmentId, displayingPatientId } = useSelector((state: RootState) => ({
    newAppointmentOpen: state.state.newAppointmentOpen,
    editAppointmentId: state.state.editAppointmentId,
    displayingPatientId: state.state.displayingPatientId,
  }))

  const currentTabRef = useRef(currentTab)
  const appOpenRef = useRef(newAppointmentOpen)
  const appIdRef = useRef(editAppointmentId)
  const patientIdRef = useRef(displayingPatientId)

  // Update refs on change
  useEffect(() => {
    currentTabRef.current = currentTab
    appOpenRef.current = newAppointmentOpen
    appIdRef.current = editAppointmentId
    patientIdRef.current = displayingPatientId
  }, [currentTab, newAppointmentOpen, editAppointmentId, displayingPatientId])

  // Bind event handler
  useEffect(() => {
    const handleClickOutside = e => {
      if (
        currentTabRef.current !== null &&
        !appOpenRef.current &&
        !appIdRef.current &&
        !patientIdRef.current &&
        !sidebarRef.current?.contains(e.target) &&
        sidebarRef.current !== e.target.current
      ) {
        setCurrentTab(null)
      }
    }
    document.addEventListener('mouseup', handleClickOutside)
    return () => document.removeEventListener('mouseup', handleClickOutside)
  }, [])

  return (
    <sidebarContext.Provider
      value={{
        currentTab,
        setCurrentTab,
      }}
    >
      <div className="icon-sidebar" ref={sidebarRef}>
        <div className="icon-sidebar-wrapper border-end">
          <div className="icon-sidebar-nav">
            <Nav variant="pills" className="flex-column">
              <SidebarLink tabId="filter" tooltip="Filters">
                <Filter size={30} />
              </SidebarLink>
              <SidebarLink tabId="appointments" tooltip="Today">
                <Today size={30} />
              </SidebarLink>
            </Nav>
          </div>
        </div>
        <AnimatePresence mode="wait">
          {currentTab !== null ? <SidebarTab id={currentTab} panel={tabs[currentTab]} /> : ''}
        </AnimatePresence>
      </div>
    </sidebarContext.Provider>
  )
}

export default CalendarSidebar
