import useModal from 'hooks/useModal'
import { useDispatch } from 'react-redux'
import { setDisplayingPatientId } from 'redux/stateSlice'
import CustomModal from './CustomModal'

const PatientChanges = () => {
  const { closeModal } = useModal('UNSAVED_PATIENT_CHANGES')

  const dispatch = useDispatch()

  const discardChanges = async () => {
    closeModal()
    dispatch(setDisplayingPatientId(null))
  }

  return (
    <CustomModal
      action={discardChanges}
      actionText="Discard changes"
      name="UNSAVED_PATIENT_CHANGES"
      title="You have unsaved changes"
    >
      <p>Are you sure you want to continue?</p>
    </CustomModal>
  )
}

export default PatientChanges
