import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import { Field, useFormikContext } from 'formik'
import moment from 'moment'
import { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import useData from 'hooks/useData'
import RemoveButton from 'components/RemoveButton'

/**
 * The table inside the vaccines tab, ideally should be a separate component to deal
 * with the arrayHelpers passed down from Formik's <FieldArray> component
 */
const TestsTable = ({ isEditing, handleAdd, handleRemove, handleChange, handleReplace }) => {
  const { values, isSubmitting } = useFormikContext<Patient>()
  const data = useData()

  const { testTypes, labs, nurses } = {
    testTypes: data.testTypes.all(),
    labs: data.labs.all(),
    nurses: data.nurses.all(),
  }

  const tableData = useMemo(
    () =>
      [...values.tests].map(test => {
        return {
          test: data.testTypes.one(test.type.id)?.title || '',
          date: new Date(test.date),
          lab: data.labs.one(test.lab.id)?.title || '',
          administeredBy: `${data.nurses.one(test.administerer.id)?.title}`,
        }
      }),
    [values]
  )

  const columnHelper = createColumnHelper<(typeof tableData)[number]>()

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor('date', {
        header: 'Date',
        cell: info => (
          <Field
            as={Form.Control}
            disabled={!isEditing || isSubmitting}
            type="date"
            name={`tests[${info.row.index}].date`}
          />
        ),
      }),
      columnHelper.accessor('test', {
        header: 'Test',
        cell: info => (
          <Field disabled={!isEditing || isSubmitting} as={Form.Select} name={`tests[${info.row.index}].type.id`}>
            <option value="default" selected disabled>
              Please select
            </option>
            {testTypes.length
              ? testTypes.map(testType => (
                  <option key={testType.id} value={testType.id}>
                    {testType.title}
                  </option>
                ))
              : ''}
          </Field>
        ),
      }),
      columnHelper.accessor('lab', {
        header: 'Laboratory',
        cell: info => (
          <Field disabled={!isEditing || isSubmitting} as={Form.Select} name={`tests[${info.row.index}].lab.id`}>
            <option value="default" selected disabled>
              Please select
            </option>
            {labs.length
              ? labs.map(lab => (
                  <option key={lab.id} value={lab.id}>
                    {lab.title}
                  </option>
                ))
              : ''}
          </Field>
        ),
      }),
      columnHelper.accessor('administeredBy', {
        header: 'Administered by',
        cell: info => (
          <Field
            disabled={!isEditing || isSubmitting}
            as={Form.Select}
            name={`tests[${info.row.index}].administerer.id`}
          >
            <option value="default" selected disabled>
              Please select
            </option>
            {nurses.map(nurse => (
              <option key={nurse.id} value={nurse.id}>
                {nurse.title}
              </option>
            ))}
          </Field>
        ),
      }),
      columnHelper.display({
        id: 'remove',
        header: '',
        cell: info => (
          <RemoveButton
            disabled={!isEditing || isSubmitting}
            type="button"
            onClick={() => handleRemove(info.row.index)}
          />
        ),
      }),
    ],
    [isEditing, !isEditing || isSubmitting]
  )

  return <FunctionalTable data={tableData} columns={tableColumns} />
}

export default TestsTable
