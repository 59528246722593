import React, { useEffect, useState } from 'react'
import { Offcanvas, Row, Col, CloseButton } from 'react-bootstrap'
import { RootState } from 'redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { closeNewAppointment, setNewAppointmentDate } from 'redux/stateSlice'
import { addLoadedAppointment } from 'redux/calendarSlice'
import { Formik, FormikHelpers } from 'formik'
import useApi from 'hooks/useApi'
import useToast from 'hooks/useToast'
import AvailabilityGrid from 'components/AvailabilityGrid'
import useAvailability from 'hooks/useAvailability'
import useHelpers from 'hooks/useHelpers'
import AppointmentSummary from 'components/AppointmentSummary'
import AppointmentPatients from 'components/AppointmentPatients'
import appointmentCreateSchema from 'schemas/appointment-create-schema'
import { AppointmentSchemaType } from 'schemas/appointment-schema'
import { add15MinsToDate } from 'helpers/date'
import NewPatientConfirmationModal from 'components/NewPatientConfirmationModal'
import moment from 'moment'

/**
 * Edit appointments offcanvas
 */
const NewAppointment: React.FC = () => {
  // State
  const { open, newAppointmentDate, newAppointmentRoom } = useSelector((state: RootState) => ({
    open: state.state.newAppointmentOpen,
    newAppointmentDate: state.state.newAppointmentDate as string,
    newAppointmentRoom: state.state.newAppointmentRoom as string
  }))

  const dispatch = useDispatch()
  const api = useApi()
  const toast = useToast()
  const helpers = useHelpers()

  const { isCalculating, timeSlots, availability, loadAvailability } = useAvailability(newAppointmentDate)

  const initialValues: AppointmentSchemaType = {
    intent: 'create',
    title: 'New appointment',
    selections: [{
      startDate: newAppointmentDate,
      endDate: add15MinsToDate(new Date(newAppointmentDate)).toISOString(),
      roomId: newAppointmentRoom,
    }],
    statusId: '1',
    typeId: '3',
    existingPatients: [],
    newPatients: [],
    notes: [],
  }

  const [hasAcknowledgedConfirmation, setHasAcknowledgedConfirmation] = useState<true | false | null>(null)

  // TODO: reuse this on new appointments offcanvas
  const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
    const { setSubmitting, resetForm } = formikHelpers

    if (values.newPatients.length > 0 && !hasAcknowledgedConfirmation) {
      let areAnyNewPatientsTravelling = false
      values.newPatients.forEach((newPatient) => {
        if (newPatient.isTravelling && newPatient.sendRegistrationEmail) {
          areAnyNewPatientsTravelling = true
        }
      })
      
      if (areAnyNewPatientsTravelling) {
        setHasAcknowledgedConfirmation(false)
        return
      }
    }

    if (values) {
      const submission = await api.appointments.book(helpers.appointments.formatCreateSchemaForRequest(values))
      if (submission.errors) {
        toast.error({
          title: 'Error booking appointments',
          text: submission.errors[0].body,
        })
        return
      }

      // 1. Reset appointment info
      resetForm({ values: { ...initialValues } })
      dispatch(closeNewAppointment())

      const successfulAppointments: Appointment[] = []
      const failedAppointments: FailedAppointment[] = []
      // 2. Update calendar appointments with new Appointment
      submission.data.forEach((appointment) => {
        // Discriminating successful appointments
        if ('title' in appointment) {
          if (helpers.appointments.isInLoadedRange(appointment.startDate, appointment.endDate)) {
            dispatch(addLoadedAppointment(appointment))
          }
          successfulAppointments.push(appointment)
        // Failed appointments
        } else {
          // todo: make this dispatchable so that failed appointments are then reflected on the calendar
          // either by dispatching as seen below, or modifying AppointmentsProvider to directly expose loadData() functionality
          // dispatch(addLoadedAppointment(appointment))
          failedAppointments.push(appointment)
        }
      })

      let toastSuccessMessage: string = ''
      if (successfulAppointments.length === 1) {
        toastSuccessMessage = `Successfully booked a new appointment for ${new Date(successfulAppointments[0].startDate).toDateString()}` 
      } else if (successfulAppointments.length > 1) {
        const successfulAppointmentMessages = (successfulAppointments.map((appointment) => {
          const time = 
            `${new Date(appointment.startDate).toLocaleTimeString('en-gb', {
              hour: '2-digit',
              minute: '2-digit',
            })} - ${new Date(appointment.endDate).toLocaleTimeString('en-gb', {
              hour: '2-digit',
              minute: '2-digit',
            })}`
          return `${new Date(appointment.startDate).toDateString() } at ${time} in ${appointment.room.title} room`
        }))
        toastSuccessMessage = `Successfully booked new appointments for: ${successfulAppointmentMessages.join(', ')}`
      }

      if (toastSuccessMessage) {
        toast.success({
          title: 'Appointments',
          text: toastSuccessMessage,
          timestamp: true,
        })
      }

      failedAppointments.forEach((failedAppointment) => {
        if (failedAppointment.startDate.length > 0 && failedAppointment.endDate.length > 0) {
          toast.error({title: 'Error booking appointment', text: `${failedAppointment.startDate[0].message}: ${moment(failedAppointment.startDate[0].data).local().format('DD/MM/YYYY HH:mm:ss')}. ${failedAppointment.endDate[0].message} ${moment(failedAppointment.endDate[0].data).local().format('DD-MM-YYYY HH:mm:ss')}!`, timestamp: true, persist: true })
        } else if (failedAppointment.startDate.length > 0) {
          toast.error({title: 'Error booking appointment', text: `${failedAppointment.startDate[0].message}: ${moment(failedAppointment.startDate[0].data).local().format('DD/MM/YYYY HH:mm:ss')}!`, timestamp: true, persist: true })
        } else if (failedAppointment.endDate.length > 0) {
          toast.error({title: 'Error booking appointment', text: `${failedAppointment.endDate[0].message} ${moment(failedAppointment.endDate[0].data).local().format('DD-MM-YYYY HH:mm:ss')}!`, timestamp: true, persist: true })
        }
      })

      setSubmitting(false)
    }
  }

  // Fetch availability when the user opens the offcanvas
  useEffect(() => {
    if (open && newAppointmentDate) {
      loadAvailability()
    }
  }, [open, newAppointmentDate])

  return (
    <Offcanvas
      show={open}
      onHide={() => {
        dispatch(closeNewAppointment())
      }}
      onExited={() => dispatch(setNewAppointmentDate(null))}
      placement="end"
      backdropClassName="new-appointment-backdrop"
      className="new-appointment-offcanvas"
    >
      <Offcanvas.Header className="border-bottom border-2">
        <h3 className="mb-0">New Appointment</h3>
        <CloseButton onClick={() => dispatch(closeNewAppointment())} />
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Formik
          initialValues={{ ...initialValues }}
          onSubmit={handleSubmit}
          validationSchema={appointmentCreateSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {() => {
            return (
              <>
                <Row>
                  <Col xs={12}>
                    <AppointmentPatients setHasAcknowledgedConfirmation={setHasAcknowledgedConfirmation} />
                  </Col>
                </Row>
                <hr className="my-5" />
                <Row>
                  <AvailabilityGrid
                    timeSlots={timeSlots}
                    availability={availability}
                    isCalculating={isCalculating}
                    onDateChange={val => dispatch(setNewAppointmentDate(val))}
                    alwaysActive
                  />
                </Row>
                <hr className="my-5" />
                <Row>
                  <Col>
                    <AppointmentSummary />
                  </Col>
                </Row>
                <NewPatientConfirmationModal hasAcknowledgedConfirmation={hasAcknowledgedConfirmation} setHasAcknowledgedConfirmation={setHasAcknowledgedConfirmation} />
              </>
            )
          }}
        </Formik>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default NewAppointment
