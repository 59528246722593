import CustomModal from './CustomModal'
import { useState, useEffect, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import useHelpers from 'hooks/useHelpers'
import useData from 'hooks/useData'
import useModal from 'hooks/useModal'
import useApi from 'hooks/useApi'
import useToast from 'hooks/useToast'
import { useDispatch } from 'react-redux'
import { updateNurseAvailability } from 'redux/calendarSlice'

interface NurseAvailabilityProps {
  date: string
}

const UpdateNurseAvailability = ({ date }: NurseAvailabilityProps) => {
  const formatDate = (date: Date) => date.toISOString().split('T')[0]

  const [_date, setDate] = useState((date && formatDate(new Date(date))) || formatDate(new Date()))

  const [_initialActiveNurses, setInitialActiveNurses] = useState<number[]>([])
  const [_activeNurses, setActiveNurses] = useState<number[]>([])

  const { isOpen, closeModal } = useModal('NURSE_AVAILABILITY')

  const helpers = useHelpers()
  const data = useData()
  const api = useApi()
  const toast = useToast()
  const dispatch = useDispatch()

  const nurses = useMemo(() => {
    return data.nurses.all().filter(nurse => !Boolean(nurse.system))
  }, [])

  // Update the modal when the date
  useEffect(() => {
    if (date) {
      setDate(formatDate(new Date(date)))
      setInitialActiveNurses(helpers.nurseAvailability.getActiveNurses(new Date(date)))
    }
  }, [date])

  // Reset activeNurses when date changes
  useEffect(() => {
    setInitialActiveNurses(helpers.nurseAvailability.getActiveNurses(new Date(_date)))
  }, [_date])

  // Reset active nurses
  useEffect(() => {
    setActiveNurses([..._initialActiveNurses])
  }, [isOpen, _initialActiveNurses])

  const handleUpdateAvailability = async () => {
    const { data, errors } = await api.nurseAvailability.update({
      date: _date,
      userIds: _activeNurses,
    })

    if (errors) {
      toast.error({
        title: 'Error updating staff availability',
        text: errors[0].body,
      })
      return
    }

    setInitialActiveNurses(data.userIds)
    dispatch(updateNurseAvailability(data))

    toast.success({
      title: 'Update successful',
      text: `Successfully updated staff availability for ${new Date(_date).toDateString()}`,
    })

    closeModal()
  }

  return (
    <CustomModal
      name="NURSE_AVAILABILITY"
      title="Update staff availability"
      action={handleUpdateAvailability}
      actionText="Save"
      actionDisabled={
        _initialActiveNurses.length === _activeNurses.length &&
        _activeNurses.every(id => _initialActiveNurses?.includes(id))
      }
    >
      <div className="d-flex flex-column gap-3">
        <Form.Control type="date" value={_date} onChange={e => setDate(e.target.value)} />
        <div>
          {nurses.length
            ? nurses.map(nurse => {
                return (
                  <Form.Check
                    label={nurse.title}
                    key={nurse.id}
                    value={nurse.id}
                    id={`nurse-availability:nurse:${nurse.id}`}
                    checked={_activeNurses.includes(nurse.id)}
                    onChange={e => {
                      const value = parseInt(e.target.value)
                      if (_activeNurses.includes(value)) {
                        setActiveNurses(prev => {
                          const _new = [...prev]
                          _new.splice(_new.indexOf(value), 1)
                          return _new
                        })
                      } else {
                        setActiveNurses(prev => [...prev, value])
                      }
                    }}
                  />
                )
              })
            : 'No nurses found in system.'}
        </div>
      </div>
    </CustomModal>
  )
}

export default UpdateNurseAvailability
