import LoginForm from 'components/forms/LoginForm'
import { Container, Row, Col } from 'react-bootstrap'
import { ReactComponent as Logo } from 'assets/svg/logo.svg'

const LoginView = () => {
  return (
    <Container className='login-container'>
      <Row className='justify-content-center'>
        <Col lg={9} xl={8} xxl={7}>
          <div className="login-component d-flex flex-column gap-5 border border-secondary rounded">
            <Row className='justify-content-center'>
              <Col xs={10} md={8}>
                <Logo/>
              </Col>
            </Row>
            <h1 className='mb-0'>Log In</h1>
            <LoginForm/>
          </div>
        </Col>
      </Row>
    </Container>

  )
}

export default LoginView