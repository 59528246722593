import CustomModal from './CustomModal'
import { useDispatch } from 'react-redux'
import { removeLoadedAppointment } from 'redux/calendarSlice'
import { setEditAppointmentId } from 'redux/stateSlice'
import useModal from 'hooks/useModal'
import useApi from 'hooks/useApi'
import useToast from 'hooks/useToast'
import useHelpers from 'hooks/useHelpers'

interface DeleteAppointmentProps {
  appointmentId: number
  initialStart: string
  initialEnd: string
}

const DeleteAppointment = ({ appointmentId, initialStart, initialEnd }: DeleteAppointmentProps) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const api = useApi()
  const helpers = useHelpers()
  const { closeModal, setLoading } = useModal('DELETE_APPOINTMENT')

  const handleDeleteAppointment = async () => {
    const deleteRequest = await api.appointments.delete(appointmentId)
    if (deleteRequest.errors) {
      // TODO: do something with errors
      setLoading(false)
      return
    }

    toast.success({
      title: 'Appointments',
      text: 'Successfully deleted appointment.',
      timestamp: true,
    })

    // Remove appointment from view if in loaded range
    if (helpers.appointments.isInLoadedRange(initialStart, initialEnd)) {
      dispatch(removeLoadedAppointment(appointmentId))
    }

    closeModal()
    dispatch(setEditAppointmentId(null))
  }

  return (
    <CustomModal
      name="DELETE_APPOINTMENT"
      action={handleDeleteAppointment}
      title="Delete Appointment"
      actionText="Delete"
    >
      <p>Are you sure you want to delete this appointment?</p>
    </CustomModal>
  )
}

export default DeleteAppointment
