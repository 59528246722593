import useApi from 'hooks/useApi'
import { Form, ListGroup, Spinner, FormControlProps } from 'react-bootstrap'
import { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import { useDispatch } from 'react-redux'
import { notify } from 'redux/stateSlice'
import 'scss/components/client-search.scss'

interface Props extends FormControlProps {
  children?: React.ReactNode | React.ReactNode[]
  onResultClick?: (patient: {
    dob: string
    email: string
    firstName: string
    id: number
    lastName: string
    mobile: string
    telephone: string
  }) => void
  containerProps?: object
}

const ClientSearch = ({ children, onResultClick, containerProps = {}, ...props }: Props) => {
  const api = useApi()
  const dispatch = useDispatch()

  const [isFetching, setIsFetching] = useState(false)
  const [inFocus, setInFocus] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [data, setData] = useState<BasicPatient[]>([])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value
    setSearchQuery(value)
  }

  const handleSearch = useCallback(
    debounce(async (query: string[]) => {
      const results = await api.patients.search(query)
      if (results.errors) {
        dispatch(
          notify({
            title: 'Patient Search',
            text: results.errors[0].body,
            variation: 'danger',
          })
        )
      } else {
        setData(results.data.slice(0, 15))
      }
      setIsFetching(false)
    }, 250),
    []
  )

  useEffect(() => {
    if (searchQuery.length > 2) {
      setIsFetching(true)
      handleSearch(searchQuery.trim().split(' '))
    }
  }, [searchQuery])

  const handleFocus = e => {
    const { type, target, currentTarget, relatedTarget } = e
    switch (type) {
      case 'focus':
        if (currentTarget !== target) {
          setInFocus(true)
        }
        break
      case 'blur':
        if (currentTarget !== target && !currentTarget.contains(relatedTarget)) {
          setInFocus(false)
        }
        break
    }
  }

  return (
    <div
      className={`position-relative auto-suggest-input ${searchQuery.length > 2 && inFocus ? 'show' : ''}`}
      onFocus={handleFocus}
      onBlur={handleFocus}
      {...containerProps}
    >
      <Form.Control type="search" {...props} value={searchQuery} onChange={handleChange} />
      {searchQuery.length > 2 ? (
        <ListGroup className="results-container">
          {isFetching ? (
            <ListGroup.Item>
              Loading...
              <Spinner size="sm" animation="border" />
            </ListGroup.Item>
          ) : data === undefined ? (
            ''
          ) : inFocus ? (
            data && data.length > 0 ? (
              data.map((result, index) => {
                return (
                  <ListGroup.Item
                    key={index}
                    onClick={onResultClick ? () => onResultClick(result) : undefined}
                    action
                    tabIndex={0}
                    data-patient-id={result.id}
                    className="d-flex justify-content-between"
                  >
                    <span>
                      {result.firstName} {result.lastName}
                    </span>
                    <span>{new Date(result.dob).toLocaleDateString()}</span>
                  </ListGroup.Item>
                )
              })
            ) : (
              <ListGroup.Item>No results found.</ListGroup.Item>
            )
          ) : (
            ''
          )}
        </ListGroup>
      ) : (
        ''
      )}
      {children}
    </div>
  )
}

export default ClientSearch
