import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'

/**
 * This hook acts as a 'local' API for querying static data stored in redux
 */
const useData = () => {
  const data = useSelector((state: RootState) => state.data)

  // Cast functions object as this type for correct intellisense
  // Hover over 'DataFunctions' to see
  type DataFunctions = {
    [key in keyof typeof data]: {
      all: () => typeof data[key]
      one: (id: number | string) => typeof data[key][number] | null
    }
  }

  // Create dynamic object of API functions for every data array 😀
  const functions = {}

  Object.entries(data).forEach(([dataKey, dataItems]) => {
    functions[dataKey] = {
      all: () => dataItems,
      one: (id: number | string) => dataItems.find(element => element.id === Number(id)) || null,
    }
  })

  return functions as DataFunctions
}

export default useData
