import { PropsWithChildren } from 'react'
import { useSidebarContext, SidebarTabId } from './CalendarSidebar'
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap'

interface SidebarLinkProps extends PropsWithChildren {
  tabId: SidebarTabId
  tooltip: string
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ children, tabId, tooltip }) => {
  const { currentTab, setCurrentTab } = useSidebarContext()

  if (currentTab !== tabId) {
    return (
      <Nav.Item>
        <OverlayTrigger
          key={tabId}
          placement="right"
          overlay={
            <Tooltip id={`sidebarlink-tooltip:${tabId}`} placement="right">
              {tooltip}
            </Tooltip>
          }
        >
          <Nav.Link
            className={`border-bottom py-3 ${tabId === currentTab ? 'active' : ''}`}
            onClick={() => {
              setCurrentTab(currentTab === tabId ? null : tabId)
            }}
          >
            {children}
          </Nav.Link>
        </OverlayTrigger>
      </Nav.Item>
    )
  } else {
    return (
      <Nav.Item>
        <Nav.Link
          className={`border-bottom py-3 ${tabId === currentTab ? 'active' : ''}`}
          onClick={() => {
            setCurrentTab(currentTab === tabId ? null : tabId)
          }}
        >
          {children}
        </Nav.Link>
      </Nav.Item>
    )
  }
}

export default SidebarLink
