import moment from 'moment'
import { Form, Table, Spinner, Button } from 'react-bootstrap'
import { Clock } from './Icons'
import { useFormikContext } from 'formik'
import { AvailabilityRoom } from 'hooks/useAvailability'
import { AppointmentSchemaType } from 'schemas/appointment-schema'
import { isDateRangeOverlapping } from 'helpers/date'
import { memo, useCallback } from 'react'

interface BaseProps {
  isCalculating: boolean
  timeSlots: Date[]
  availability: AvailabilityRoom[]
  onDateChange?: (value: string) => void
}

interface DependentFinderProps extends BaseProps {
  isEditingDate: boolean
  setIsEditingDate: React.Dispatch<React.SetStateAction<boolean>>
  onCancel: () => void
  alwaysActive?: undefined
}

interface ActiveFinderProps extends BaseProps {
  isEditingDate?: undefined
  setIsEditingDate?: undefined
  onCancel?: undefined
  alwaysActive: boolean
}

// TODO: type onSelect
const AvailabilityGrid: React.FC<DependentFinderProps | ActiveFinderProps> = ({
  isCalculating,
  isEditingDate,
  onCancel,
  setIsEditingDate,
  timeSlots,
  availability,
  onDateChange,
  alwaysActive,
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<AppointmentSchemaType>()

  const selections = values.intent === 'create' ? values.selections : []

  const handleCreateTimeslot = useCallback((room: AvailabilityRoom, startDate: string, endDate: string) => {
    if (selections.length === 0) {
      return
    }

    const roomId = room.roomId.toString()

    const previousSelections = [ ...selections ]

    // add new selection
    // updatedSelections.push({startDate: startDate, endDate: endDate, roomId: roomId})

    // for any selections that exist at the same start time, we want to remove or replace so we only have 1 (or 0) at the same start time
    const conflictingSelection = previousSelections.find((selection) => selection.startDate === startDate)
    const updatedSelections = previousSelections.filter((selection) => selection.startDate !== startDate)

    // If the start date is the same as the conflict but the end date or room id are different, then we should replace rather than simply just remove
    if (conflictingSelection?.endDate !== endDate || conflictingSelection.roomId !== roomId) {
      updatedSelections.push({startDate: startDate, endDate: endDate, roomId: roomId})
    }

    if (updatedSelections.length < 1) {
      return
    }

    setFieldValue(`selections`, updatedSelections)
  }, [setFieldValue, selections])

  const handleEditTimeslot = useCallback((room: AvailabilityRoom, startDate: string, endDate: string) => {
    setFieldValue('roomId', room.roomId.toString())
    // TODO: force validation here
    // This setTimeout is needed due to a bug in formik
    // @see https://github.com/jaredpalmer/formik/issues/2059
    setTimeout(() => setFieldTouched('roomId', true))
    setFieldValue('startDate', startDate)
    setFieldValue('endDate', endDate)
  }, [setFieldValue, setFieldTouched])

  const handleTimeslotSelection = useCallback((room: AvailabilityRoom, startDate: string, endDate: string) => {
    if (values.intent === 'edit') {
      handleEditTimeslot(room, startDate, endDate)
    } else {
      handleCreateTimeslot(room, startDate, endDate)
    }
  }, [values.intent, handleEditTimeslot, handleCreateTimeslot])

  return (
    <div>
      {/* TODO: Rework to support multiple time selections */}
      <div className="d-flex gap-4 align-items-center mb-4">
        <h4 className="mb-0 text-nowrap">Date and Time</h4>
        <Form.Control
          type="date"
          style={{ maxWidth: 300 }}
          disabled={isCalculating || (setIsEditingDate !== undefined && !isEditingDate)}
          value={values.intent === 'edit' ? moment(values.startDate).format('YYYY-MM-DD') : moment(values.selections[0].startDate).format('YYYY-MM-DD')}
          name="startDate"
          // min={new Date().toISOString().split('T')[0]}
          onChange={e => {
            if (new Date(e.target.value).getDay() === 0) {
              e.preventDefault()
              return
            }
            setFieldValue('roomId', '')
            setFieldValue('endDate', '')
            setFieldValue('startDate', e.target.value)
            onDateChange?.(e.target.value)
          }}
        />
        {!alwaysActive ? (
          !isEditingDate ? (
            <Button onClick={() => setIsEditingDate && setIsEditingDate(true)}>Edit</Button>
          ) : (
            <Button
              onClick={() => {
                setIsEditingDate && setIsEditingDate(false)
                onCancel && onCancel()
              }}
            >
              Cancel
            </Button>
          )
        ) : (
          ''
        )}
      </div>
      {isEditingDate || alwaysActive ? (
        <div className="position-relative">
          {isCalculating ? (
            <span className="my-4">
              <Spinner size="sm" /> Loading available appointments...
            </span>
          ) : availability.length ? (
            <>
              <p className="mt-4 mb-5">
                Showing available appointments for{'  '}
                {new Date(values.intent === 'edit' ? values.startDate : values.selections[0].startDate).toLocaleDateString('en-gb', {
                  dateStyle: 'full',
                })}
              </p>
              <Table size="sm" bordered hover className="availability-table">
                <thead className="bg-secondary-accent">
                  <tr>
                    <th>Time</th>
                    {availability.map(room => (
                      <th key={room.roomId}>{room.roomTitle}</th>
                    ))}
                  </tr>
                </thead>
                <AvailabilityGridBody availability={availability} values={values} handleTimeslotSelection={handleTimeslotSelection} timeSlots={timeSlots}/>
              </Table>
            </>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

// Memoised since this was an expensive component to re-render
// e.g., each keystroke in a field relating to adding a patient modifies values, which means that all the availability logic in this component would
// be recomputed on every keystroke, resulting in very noticable visual lag
const AvailabilityGridBody = memo(function AvailabilityGridBody({ timeSlots, availability, values, handleTimeslotSelection }: { timeSlots: Date[]; availability: AvailabilityRoom[]; values: AppointmentSchemaType; handleTimeslotSelection: (room: AvailabilityRoom, startDate: string, endDate: string) => void;}) {
  return (
    <tbody>
      {timeSlots.map(timeSlot => {
        return (
          <tr key={timeSlot.toTimeString()}>
            <td>
              <div className="d-flex gap-2 align-items-center">
                <Clock />
                {timeSlot.toLocaleTimeString('en-gb', {
                  timeStyle: 'short',
                })}
              </div>
            </td>
            {availability.map(room => {
              const roomSlot = room.slots.find(
                roomSlot => roomSlot.date.toISOString() === timeSlot.toISOString()
              )

              return (
                <td key={timeSlot.toTimeString() + room.roomId}>
                  {roomSlot ? (
                    <>
                      {[15, 30, 45, 60].map(duration => {
                        const startDate = roomSlot.date.toISOString()

                        const endDate = new Date(
                          new Date(roomSlot.date).setMinutes(roomSlot.date.getMinutes() + duration)
                        ).toISOString()

                        let isInputChecked = false
                        if (values.intent === 'edit') {
                          isInputChecked = values.startDate === roomSlot.date.toISOString() &&
                                          values.endDate === endDate &&
                                          values.roomId === room.roomId.toString()
                        } else if (values.intent === 'create') {
                          if (values)

                          isInputChecked = Boolean(values.selections.find(selection => {
                            return selection.startDate === roomSlot.date.toISOString() && selection.endDate === endDate && selection.roomId === room.roomId.toString()
                          }))
                          // isInputChecked = values.selections[0].startDate === roomSlot.date.toISOString() &&
                          //                 values.selections[0].endDate === endDate &&
                          //                 values.selections[0].roomId === room.roomId.toString()
                        }

                        const currentSlotStartDate = new Date(startDate)
                        const currentSlotEndDate = new Date(endDate)
                        let isSlotDisabled = !roomSlot.durations.includes(duration)
                        if (values.intent === 'create' && !isSlotDisabled) {
                          values.selections.forEach((selection) => {
                            const selectionStartDate = new Date(selection.startDate)
                            const selectionEndDate = new Date(selection.endDate)
                            if (selectionStartDate.getTime() === currentSlotStartDate.getTime()) {
                              return
                            }
                            const isSlotOverlapping = isDateRangeOverlapping(currentSlotStartDate, currentSlotEndDate, selectionStartDate, selectionEndDate, true)
                            if (isSlotOverlapping) {
                              isSlotDisabled = true
                            }
                            // // Time slots at the same time are only available if they would not conflict
                            // if (selectionStartDate.getTime() === currentSlotStartDate.getTime()) {
                            //   isSlotDisabled = false
                            // }
                          })
                        }

                        return (
                          <Form.Check
                            inline
                            key={duration}
                            type='radio'
                            label={duration === 60 ? '1 hour' : `${duration} mins`}
                            value={duration}
                            id={`${room.roomId}:${roomSlot.date.toISOString()}:${duration}`}
                            disabled={isSlotDisabled && !isInputChecked}
                            checked={isInputChecked}
                            name={`${room.roomId}:${roomSlot.date.toISOString()}`}
                            onClick={(event) => handleTimeslotSelection(room, startDate, endDate)}
                            onChange={(event) => handleTimeslotSelection(room, startDate, endDate)}
                          />
                        )
                      })}
                    </>
                  ) : (
                    ''
                  )}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}, (oldProps, newProps) => {
  if (oldProps.availability !== newProps.availability) {
    return false
  }
  if (oldProps.timeSlots !== newProps.timeSlots) {
    return false
  }
  if (oldProps.handleTimeslotSelection !== newProps.handleTimeslotSelection) {
    return false
  }

  if (oldProps.values.intent === 'create' && newProps.values.intent === 'create') {
    if (oldProps.values.selections !== newProps.values.selections) {
      return false
    }
  } else if (oldProps.values.intent === 'edit' && newProps.values.intent === 'edit') {
    if (oldProps.values.startDate !== newProps.values.startDate) {
      return false
    }
    if (oldProps.values.endDate !== newProps.values.endDate) {
      return false
    }
    if (oldProps.values.roomId !== newProps.values.roomId) {
      return false
    }
  } else {
    return false
  }

  return true
})

export default AvailabilityGrid
