import PatientTag from 'components/PatientTag'
import { Button, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { setDisplayingPatientId } from 'redux/stateSlice'
import { RootState } from 'redux/store'
import { User, Danger } from 'components/Icons'
import { useEffect, useState } from 'react'
import useToast from 'hooks/useToast'
import useApi from 'hooks/useApi'
import moment from 'moment'
import LoadingCover from 'components/LoadingCover'
import { Accordion } from 'react-bootstrap'
import 'scss/components/todays-appointments-panel.scss'

const TodaysAppointmentsPanel = () => {
  const dispatch = useDispatch()
  const api = useApi()
  const toast = useToast()
  const [loading, setLoading] = useState(true)
  const [appointments, setAppointments] = useState<Appointment[]>([])

  const [lastAvailability, setLastAvailability] = useState<RoomAvailability[]>([])

  const { roomAvailability } = useSelector((state: RootState) => ({
    roomAvailability: state.calendar.roomAvailability,
  }))

  const loadAppointments = async () => {
    setLoading(true)
    const today = moment()
    const appointmentsRequest = await api.appointments.range(
      today.startOf('day').toISOString(),
      today.endOf('day').toISOString()
    )
    if (appointmentsRequest.errors) {
      toast.error({
        title: "Error loading today's appointments",
        text: appointmentsRequest.errors[0].body,
      })
    } else {
      setAppointments(
        appointmentsRequest.data.filter(
          appointment => !['Cancelled', 'Did not attend'].includes(appointment.status.title)
        )
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    setLastAvailability([...roomAvailability])
    loadAppointments()
  }, [])

  useEffect(() => {
    const _changed = lastAvailability.filter(prev => !roomAvailability.includes(prev))
    if (_changed.length && new Date(_changed[0].date).toDateString() === new Date().toDateString()) {
      loadAppointments()
    }
  }, [roomAvailability])

  const PatientTags = ({ patients }) => {
    const onTagClick = id => dispatch(setDisplayingPatientId(id))
    return (
      <ul className="d-flex flex-wrap gap-2 list-unstyled p-0 m-0">
        {patients.map((patient, index) => (
          <li key={index}>
            <PatientTag
              as={Button}
              size="sm"
              variant="outline-secondary"
              label={`${patient.firstName} ${patient.lastName}`}
              onClick={() => onTagClick(patient.id)}
            />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className="sidebar-panel todays-appointments d-flex flex-column">
      <h2
        className="h3"
        style={{
          opacity: loading ? 0 : 1,
        }}
      >
        Todays Appointments
      </h2>
      {loading ? (
        <LoadingCover text="Loading appointments..." />
      ) : (
        <>
          {appointments.length ? (
            <div className="mt-4">
              <Accordion>
                {appointments.map(appointment => {
                  return (
                    <Accordion.Item key={appointment.id} eventKey={appointment.id.toString()}>
                      <Accordion.Header>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center gap-3">
                            {/* <Clock /> */}
                            <strong style={{ width: 50 }}>
                              {new Date(appointment.startDate).toLocaleTimeString('en-gb', {
                                timeStyle: 'short',
                              })}
                            </strong>
                            <span
                              // className="bg-secondary"
                              style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                                width: '25px',
                              }}
                            >
                              <User size={12} />
                              {appointment.patients.length}
                            </span>
                            <small className="justify-self-end opacity-75">{appointment.type.title}</small>
                            {appointment.status.title === 'To be confirmed' ? (
                              <Danger className="text-danger" size={15} />
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <Table size="sm" className="m-0">
                          <tbody>
                            {appointment.status.id === 2 && (
                              <tr>
                                <td className="ps-3">
                                  <strong>Status</strong>
                                </td>
                                <td>{appointment.status.title}</td>
                              </tr>
                            )}
                            <tr>
                              <td className="ps-3">
                                <strong>Room</strong>
                              </td>
                              <td>{appointment.room.title}</td>
                            </tr>
                          </tbody>
                        </Table>
                        <div className="d-flex flex-column p-3">
                          {appointment.patients.length ? <PatientTags patients={appointment.patients} /> : ''}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )
                })}
              </Accordion>
            </div>
          ) : (
            'No appointments! 🥳'
          )}
        </>
      )}
    </div>
  )
}
export default TodaysAppointmentsPanel
