import { Toast, CloseButton } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { dismissToast } from 'redux/stateSlice'
import { motion } from 'framer-motion'
import { useState, useEffect } from 'react'

type ToastVariation = 'success' | 'danger'

export interface ToastData {
  title: string
  text: string
  timestamp?: boolean
  persist?: boolean
}

export interface ToastProps {
  toastKey: number
  data: ToastData & {
    variation: ToastVariation
  }
}

export type ToastFunction<TData> = (data?: TData) => ToastProps['data']

const CustomToast: React.FC<ToastProps> = ({ toastKey, data }) => {
  const { title, text, variation, persist } = data

  const dispatch = useDispatch()

  const [date] = useState(
    new Date().toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
  )

  const handleToastClose = () => dispatch(dismissToast(toastKey))

  useEffect(() => {
    if (!persist) {
      setTimeout(() => {
        dispatch(dismissToast(toastKey))
      }, 10000)
    }
  }, [])

  const variants = {
    beforeEnter: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
  }

  return (
    <motion.div
      layout
      layoutId={`${toastKey}-${new Date().toISOString}`}
      className="toast show"
      variants={variants}
      initial="beforeEnter"
      animate="enter"
    >
      <Toast.Header className={`bg-${variation} text-white`} closeButton={false}>
        <strong className="fs-6">{title}</strong>
        <div>
          <small>{date}</small>
          <CloseButton variant="white" onClick={handleToastClose} />
        </div>
      </Toast.Header>
      <Toast.Body>{text}</Toast.Body>
    </motion.div>
  )
}

export default CustomToast
