import CalendarWidget from 'components/calendar/CalendarWidget'
import { RootState } from 'redux/store'
import { useSelector } from 'react-redux'
import { useAppointmentsContext } from 'providers/AppointmentsProvider'
import { setVisibleDateRange } from 'redux/calendarSlice'
import { useDispatch } from 'react-redux'
import LoadingCover from 'components/LoadingCover'
import FilterPanel from 'components/sidebar/panels/FilterPanel'
import 'scss/components/sidebar-panel.scss'
import { Link } from 'react-router-dom'

const CalendarView = () => {
  const { loading } = useAppointmentsContext()
  const dispatch = useDispatch()

  // State
  const { nurseAvailability, filteredAppointments } = useSelector((state: RootState) => ({
    nurseAvailability: state.calendar.nurseAvailability,
    filteredAppointments: state.calendar.filteredAppointments,
  }))

  // Update visible date range on interaction
  const handleChangeDateRange = (newStart: string, newEnd: string) => {
    dispatch(setVisibleDateRange({ start: newStart.split('T')[0], end: newEnd.split('T')[0] }))
  }

  return (
    <>
      {loading ? <LoadingCover text="Loading appointments..." /> : ''}
      <Link
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: 10,
          width: 10,
        }}
        to="testing"
      ></Link>
      <div className="view-content position-relative">
        <FilterPanel />
        <CalendarWidget
          nurseAvailability={nurseAvailability}
          appointments={filteredAppointments}
          handleChangeDateRange={handleChangeDateRange}
        />
      </div>
    </>
  )
}

export default CalendarView
