import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { AuthProvider } from 'providers/AuthProvider'
import { store } from 'redux/store'
import App from './App'
import CustomErrorBoundary from 'CustomErrorBoundary'

import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const ErrorFallback = ({ error, errorInfo }) => {
  console.log(error, errorInfo)
  return <>Error!</>
}

root.render(
  <React.StrictMode>
    <CustomErrorBoundary fallback={ErrorFallback}>
      <BrowserRouter>
        <Provider store={store}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </Provider>
      </BrowserRouter>
    </CustomErrorBoundary>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
