import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import { PatientTabProps } from '../PatientInfo'
import { camelCase } from 'lodash'
import useData from 'hooks/useData'
import { useDispatch } from 'react-redux'
import { setEditAppointmentId } from 'redux/stateSlice'

const AppointmentsTab: React.FC<PatientTabProps> = ({ patientDetails, isEditing }) => {
  const data = useData()
  const dispatch = useDispatch()

  const BadgeCell = ({ category }: { category: Category }) => {
    return (
      <span
        style={{ whiteSpace: 'nowrap' }}
        className={`text-white px-2 py-1 user-select-none rounded bg-${camelCase(category.title)}`}
      >
        {category.title}
      </span>
    )
  }

  const formatData = (appointments: Patient['appointments']) => {
    return appointments.map(appointment => ({
      id: appointment.id,
      date: new Date(appointment.startDate),
      title: appointment.title,
      type: data.appointmentTypes.one(appointment.type.id)?.title || '',
      status: data.appointmentStatuses.one(appointment.status.id)?.title || '',
    }))
  }

  type AppointmentsRow = ReturnType<typeof formatData>[number]
  const columnHelper = createColumnHelper<AppointmentsRow>()

  const tableColumns = [
    columnHelper.accessor('date', {
      header: 'Date',
      cell: info => (
        <div className="d-flex gap-3">
          <span>
            {info.getValue().toLocaleString('en-gb', {
              dateStyle: 'short',
            })}
          </span>
          <span>
            {info.getValue().toLocaleString('en-gb', {
              timeStyle: 'short',
            })}
          </span>
        </div>
      ),
      sortingFn: 'datetime',
    }),
    columnHelper.accessor('type', {
      header: 'Type',
      cell: info => <BadgeCell category={patientDetails?.appointments[info.row.index].type as Category} />,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: info => <BadgeCell category={patientDetails?.appointments[info.row.index].status as Category} />,
    }),
  ]

  return (
    <div className="w-100">
      {patientDetails?.appointments.length ? (
        <FunctionalTable
          data={formatData(patientDetails?.appointments)}
          columns={tableColumns}
          sortingState={[{ id: 'date', desc: true }]}
          rowProps={{
            onClick: (e: AppointmentsRow) => {
              dispatch(setEditAppointmentId(e.id))
            },
            style: { cursor: 'pointer' },
          }}
        />
      ) : (
        'This patient has no appointments yet.'
      )}
    </div>
  )
}

export default AppointmentsTab
