interface FormSectionProps {
  title : string,
  handleSubmit? : (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
  children : React.ReactNode
  isSubmitting? : boolean,
  className?: string
}
const FormSection = ({ title, children, className } : FormSectionProps ) => {
  return (
    <div className={`editable-section ${className}`}>
      <h5 className='mb-4 d-flex gap-3 align-items-center'>{title}</h5>
      { children }
    </div>
  )
}

export default FormSection
