import { motion } from "framer-motion"
import { useLocation } from "react-router-dom"

export const viewWrapperVariants = {
  beforeEnter: {
    opacity: 0,
    x: -50
  },
  enter: {
    opacity: 1,
    x: 0
  },
  exit: {
    opacity: 0,
    x: 50
  }
}

const ViewWrapper = ({ children }) => {

  const location = useLocation()

  return (
    <motion.div key={location.pathname} className="view-wrapper" initial='beforeEnter' animate='enter' exit='exit'>
      {children}
    </motion.div>
  )
}

export default ViewWrapper