import FilterGroup from 'components/FilterGroup'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import useAppointmentFilters from 'hooks/useAppointmentFilters'

const FilterPanel = () => {
  const filters = useAppointmentFilters()

  const { appointmentTypes, appointmentStatuses, activeTypes, activeStatuses } = useSelector((state: RootState) => ({
    appointmentTypes: state.data.appointmentTypes,
    appointmentStatuses: state.data.appointmentStatuses,
    activeTypes: state.state.activeTypes,
    activeStatuses: state.state.activeStatuses,
  }))

  return (
    <div
      className="sidebar-panel"
      style={{
        overflowY: 'auto',
      }}
    >
      <FilterGroup
        title="Appointment Type"
        categories={appointmentTypes}
        activeCategoryArray={activeTypes}
        onFilterClick={filters.toggleType}
        onAllClick={filters.toggleAllTypes}
      />
      <FilterGroup
        title="Appointment Status"
        categories={appointmentStatuses}
        activeCategoryArray={activeStatuses}
        onFilterClick={filters.toggleStatus}
        onAllClick={filters.toggleAllStatuses}
      />
      <div className="mt-3"></div>
    </div>
  )
}

export default FilterPanel
