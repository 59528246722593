import CustomToastContainer from 'components/CustomToastContainer'
import { Outlet, useLocation } from 'react-router-dom'
import IdleManager from 'components/IdleManager'
import useAuth from 'hooks/useAuth'
import NoticeBanner from 'components/NoticeBanner'
import { Danger } from 'components/Icons'

const BaseLayout = () => {
  const location = useLocation()
  const viewSlug = location.pathname.substring(1).replaceAll('/', '-') || 'index'

  const { user } = useAuth()

  return (
    <>
      {process.env.REACT_APP_ENV === 'staging' && (
        <NoticeBanner>
          <span className="d-flex align-items-center justify-content-center gap-2">
            <Danger />
            <strong>WARNING:</strong>
          </span>
          YOU ARE CURRENTLY USING THE STAGING ENVIROMMENT
        </NoticeBanner>
      )}
      <div className={`page-wrapper view:${viewSlug}`}>
        <Outlet />
        <CustomToastContainer />
        {user && <IdleManager />}
      </div>
    </>
  )
}

export default BaseLayout
