import { ToastData } from 'components/CustomToast'
import { useDispatch } from 'react-redux'
import { notify } from 'redux/stateSlice'

const useToast = () => {
  const dispatch = useDispatch()
  return {
    success: (toastData: ToastData) => {
      dispatch(
        notify({
          ...toastData,
          variation: 'success',
        })
      )
    },
    error: (toastData: ToastData) => {
      dispatch(
        notify({
          ...toastData,
          variation: 'danger',
        })
      )
    },
  }
}

export default useToast
