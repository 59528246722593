import React, { ReactElement, ReactNode, useMemo } from 'react'
import { Card, Button, Table, Form, Spinner } from 'react-bootstrap'
import TextArea from './TextArea'
import { useFormikContext, Field } from 'formik'
import { getMinutesBetween } from 'helpers/date'
import useData from 'hooks/useData'
import { Tick, Cross } from './Icons'
import { AppointmentSchemaType } from 'schemas/appointment-schema'

interface SummaryProps {
  appointmentId?: number
  handleDelete?: () => void
}

function calculateDuration(startDateString: string | null, endDateString: string | null): number | null {
  return startDateString && endDateString ? getMinutesBetween(new Date(startDateString), new Date(endDateString)) : null
}

function formatDuration(duration: number | null): string {
  return duration !== null ? duration === 60 ? '1 hour' : `${duration} minutes` : ''
}

const AppointmentSummary: React.FC<SummaryProps> = ({ appointmentId, handleDelete }) => {
  const data = useData()
  const { values, errors, isSubmitting, isValid, dirty, handleSubmit } = useFormikContext<AppointmentSchemaType>()

  const { appointmentTypes, appointmentStatuses } = {
    appointmentTypes: data.appointmentTypes.all(),
    appointmentStatuses: data.appointmentStatuses.all(),
  }

  let startDate: ReactNode = null
  let time: ReactNode = null
  let room: ReactNode = null
  let duration: ReactNode = null

  if (values.intent === 'edit') {
    startDate = <>{values.startDate
                  ? new Date(values.startDate).toLocaleDateString('en-gb', {
                      weekday: 'long',
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric',
                    })
                  : ''}</>

    time = <>
      {values.startDate && values.endDate ? (
        <>
          {new Date(values.startDate).toLocaleTimeString('en-gb', {
            hour: '2-digit',
            minute: '2-digit',
          })}{' '}
          -{' '}
          {new Date(values.endDate).toLocaleTimeString('en-gb', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </>
      ) : (
        ''
      )}
    </>
    
    room = <>{values.endDate ? data.rooms.one(values.roomId)?.title || values.roomId : ''}</>
    
    duration = <>{formatDuration(calculateDuration(values.startDate, values.endDate))}</>
  }

  const sortedSelections = values.intent === 'create' ? values.selections.sort((a, b) => {
      const dateA = new Date(a.startDate)
      const dateB = new Date(b.startDate)
      return dateA.getTime() - dateB.getTime()
  }) : []

  if (values.intent === 'create') {
    const startDateString = sortedSelections.length > 0 ? sortedSelections[0].startDate : null
    startDate = <>{startDateString
      ? new Date(startDateString).toLocaleDateString('en-gb', {
          weekday: 'long',
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })
      : ''}</>
  }

  return (
    <Card>
      <Card.Header>
        <h5>Appointment Summary</h5>
      </Card.Header>
      <Card.Body>
        <Table>
          <tbody>
            <tr>
              <td className="th fw-bold ps-0">Patients</td>
              <td>
                <ul className="list-unstyled">
                  {[...values.existingPatients].map((patient, index) => (
                    <React.Fragment key={index}>
                      {patient.firstName || patient.lastName ? (
                        <li key={index} className="d-flex align-items-center gap-2">
                          <span className="text-success d-flex align-items-center">
                            <Tick size={20} />
                          </span>
                          {patient.firstName} {patient.lastName}
                        </li>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  ))}
                  {[...values.newPatients].map((patient, index) => (
                    <React.Fragment key={index}>
                      {patient.firstName || patient.lastName ? (
                        <li key={index} className="d-flex align-items-center gap-2">
                          <span className="text-danger d-flex align-items-center">
                            <Cross size={20} />
                          </span>
                          {patient.firstName} {patient.lastName}
                        </li>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <td className="th fw-bold ps-0 pe-4">Date</td>
              <td style={{ width: '100%' }}>
                {startDate}
              </td>
            </tr>

            <tr>
              <td className="th fw-bold ps-0 pe-4">{values.intent === 'edit' || values.selections.length <= 1 ? 'Time' : 'Times'}</td>
              {values.intent === 'create' ? (
                <td style={{ width: '100%', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '8px' }}>
                  {sortedSelections.map((selection) => {
                    const duration = formatDuration(calculateDuration(selection.startDate, selection.endDate))
                    const time = 
                      `${new Date(selection.startDate).toLocaleTimeString('en-gb', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })} - ${new Date(selection.endDate).toLocaleTimeString('en-gb', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}`
                    const room = selection.endDate ? data.rooms.one(selection.roomId)?.title || selection.roomId : ''
                    return (
                      <SelectionInfoBox key={`${selection.startDate}-${selection.endDate}-${selection.roomId}`} duration={duration} room={room} time={time} />
                    )
                  })}
                </td>
            ) : (
              <td>
                {time}
              </td>
            )}
            </tr>

            {values.intent === 'edit' && (<tr>
              <td className="th fw-bold ps-0 pe-4">Room</td>
              <td>
                {room}
              </td>
            </tr>)}

            {values.intent === 'edit' && (<tr>
              <td className="th fw-bold ps-0 pe-4">Duration</td>
              <td>
                {duration}
              </td>
            </tr>)}
            
            <tr>
              <td className="th fw-bold ps-0 pe-4">Type</td>
              <td>
                <Field as={Form.Select} name="typeId">
                  {appointmentTypes.map(type => (
                    <option key={type.id} value={type.id}>
                      {type.title}
                    </option>
                  ))}
                </Field>
              </td>
            </tr>
            <tr>
              <td className="th fw-bold ps-0 pe-4">Status</td>
              <td>
                <Field as={Form.Select} name="statusId">
                  {appointmentStatuses.map(status => (
                    <option key={status.id} value={status.id}>
                      {status.title}
                    </option>
                  ))}
                </Field>
              </td>
            </tr>
            <tr>
              <td className="th fw-bold ps-0 pe-4">Notes</td>
              <td>
                <Field as={TextArea} rows={5} name="notes[0].note" />
              </td>
            </tr>
          </tbody>
        </Table>
        {/* FORM ERRORS */}
        {process.env.NODE_ENV === 'development' && Object.keys(errors).length ? (
          <>
            {/* <hr /> */}
            <pre
              style={{
                fontSize: '0.8em',
                maxHeight: '200px',
                border: '1px solid red',
                borderRadius: '6px',
                padding: '0.5rem',
              }}
            >
              <code>{JSON.stringify(errors, null, 2)}</code>
            </pre>
          </>
        ) : (
          ''
        )}
        <div className="d-flex w-100 justify-content-between align-items-center mt-4">
          <Button
            disabled={isSubmitting || !isValid || !dirty}
            onClick={() => handleSubmit()}
            variant="danger"
            className={`text-white ${isSubmitting ? 'loading' : ''}`}
          >
            {isSubmitting ? (
              <>
                <span>Loading...</span>
                <Spinner size="sm" className="ms-2" />
              </>
            ) : (
              'SAVE'
            )}
          </Button>
          {appointmentId ? (
            <Button
              variant="danger"
              className="text-white"
              onClick={() => {
                if (handleDelete !== undefined) {
                  handleDelete()
                }
              }}
            >
              DELETE
            </Button>
          ) : (
            ''
          )}
        </div>
      </Card.Body>
    </Card>
  )
}

function SelectionInfoBox({time, room, duration}: {time: string, room: string, duration: string}) {
  return (
    <div className='form-control d-grid' style={{gridTemplateColumns: 'auto 1fr', columnGap: '32px', rowGap: '8px'}}>
      <span className='font-weight-bold mb-0' style={{fontWeight: 600}}>Time:</span>
      <span>{time}</span>
      <span className='font-weight-bold mb-0' style={{fontWeight: 600}}>Room:</span>
      <span>{room}</span>
      <span className='font-weight-bold mb-0' style={{fontWeight: 600}}>Duration:</span>
      <span>{duration}</span>
    </div>
  )
}

export default AppointmentSummary
