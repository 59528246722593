import { modals } from 'redux/modalSlice'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'

const ModalRenderer = () => {
  const globalState = useSelector((state: RootState) => state.modals)

  return (
    <>
      {Object.entries(modals).map(([key, Modal]) => {
        const props = globalState[key].props
        return <Modal key={key} {...props} />
      })}
    </>
  )
}

export default ModalRenderer
