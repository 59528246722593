import { ChevronDown, ChevronUp } from 'components/Icons'
import { Button, ButtonGroup, Col, Form, Row, Table } from 'react-bootstrap'

import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  SortingState,
} from '@tanstack/react-table'

import 'scss/components/table-wrapper.scss'

/**
 * A wrapper component for tanstack/table.
 * Easily render sortable and paginated consistent tables throughout the app.
 */
const FunctionalTable = ({
  data,
  columns,
  rowProps = {},
  options = {},
  sortingState = [],
}: {
  data: any
  columns: any
  rowProps?: any
  options?: any
  sortingState?: SortingState
}) => {
  const table = useReactTable({
    data: data,
    columns: columns,
    meta: options,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: sortingState,
      // HACK: CBR: Set initial page length to something really high
      pagination: {
        pageSize: 100,
      }
    },
  })

  return (
    <div className="table-wrapper w-100">
      {/* CBR: Disabled pagination navigation as not used */}
      {/* {data.length > 10 ? (
        <Row className="pagination d-flex flex-nowrap align-items-center justify-content-between mb-4">
          <Col xs="auto">
            <Form.Select
              size="sm"
              value={table.getState().pagination.pageSize}
              onChange={e => table.setPageSize(Number(e.target.value))}
            >
              {[10, 20, 30, 40, 50].map(pageSize => {
                return data.length > pageSize - 10 ? (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ) : (
                  ''
                )
              })}
            </Form.Select>
          </Col>
          {table.getPageCount() > 1 ? (
            <Col xs="auto">
              <span className="me-5">
                Page{' '}
                <strong>
                  {table.getState().pagination.pageIndex + 1} of {table.getPageOptions().length}
                </strong>
              </span>
              <ButtonGroup>
                <Button size="sm" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
                  <span className="bi bi-chevron-left"></span>
                </Button>
                <Button size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                  <span className="bi bi-chevron-right"></span>
                </Button>
              </ButtonGroup>
            </Col>
          ) : (
            ''
          )}
        </Row>
      ) : (
        ''
      )} */}

      <Table responsive hover {...table.options.meta}>
        <thead className="bg-secondary text-white">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  <span
                    className={header.column.getCanSort() ? 'cursor-pointer user-select-none' : ''}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}{' '}
                    {{ asc: <ChevronUp />, desc: <ChevronDown /> }[header.column.getIsSorted() as string] ?? null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getPaginationRowModel().rows.map((row, index) => {
            return (
              <tr key={row.id} {...rowProps} onClick={rowProps.onClick ? () => rowProps.onClick(row.original) : null}>
                {row.getVisibleCells().map(cell => {
                  const cellMeta = cell.column.columnDef.meta || {}
                  return (
                    <td key={cell.id} {...cellMeta}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default FunctionalTable
