import { Plus } from 'components/Icons'
import { Button } from 'react-bootstrap'
import NotesTable from '../tables/NotesTable'
import { FieldArray, useFormikContext } from 'formik'
import { PatientTabProps } from '../PatientInfo'
import useAuth from 'hooks/useAuth'
import moment from 'moment'

import { type User } from 'providers/AuthProvider'

const NotesTab: React.FC<PatientTabProps> = ({ isEditing }) => {
  const { user } = useAuth()
  const { isSubmitting } = useFormikContext<Patient>()

  return (
    <div>
      <h4 className="h5 mb-4">General Notes</h4>
      <FieldArray name="notes">
        {({ push: addNote, remove: deleteNote }) => {
          return (
            <>
              <NotesTable handleRemove={deleteNote} isEditing={isEditing} />
              <div className="d-flex justify-content-center align-item-center py-4">
                <Button
                  size="sm"
                  disabled={!isEditing || isSubmitting}
                  onClick={() => {
                    addNote({
                      note: '',
                      author: user,
                      dateCreated: moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
                      travelHealthInfo: {
                        bite_avoidance: 0,
                        emailed_info: 0,
                        food_water_hygiene: 0,
                        infectious_diseases: 0,
                        insurance: 0,
                        personal_safety: 0,
                        side_effects: 0,
                        sun_environment: 0,
                      },
                    })
                  }}
                >
                  Add note <Plus />
                </Button>
              </div>
            </>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default NotesTab
