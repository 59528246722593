interface NoticeBannerProps {
  children: React.ReactNode
}

const NoticeBanner = ({ children }: NoticeBannerProps) => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-wrap py-2 px-2 bg-warning gap-2">
      {children}
    </div>
  )
}

export default NoticeBanner
