import useAuth from './useAuth'
import dayjs from 'dayjs'

const useCanEdit = () => {
  const { user } = useAuth()

  const userCanEdit = (nurseId: string | number, date: Date) => {
    const isAuthor = user && user.id == nurseId

    // Modify provided date to midnight
    const now = dayjs()
    const _date = dayjs(date).endOf('day')
    const isAfterMidnight = now.diff(_date, 'second') > 0

    return isAuthor && !isAfterMidnight
  }

  return userCanEdit
}

export default useCanEdit
