import * as Yup from 'yup'

// Yup currently has a bug where .test on schema objects does not work with formik
// This is why 'contactValidation' and 'patientValidation' are both inline inside
// the schemas themselves
// --> @see https://github.com/jaredpalmer/formik/issues/2146 for more information

// ----------------------------------------- Begin Reusable

const patient = Yup.object({
  id: Yup.string().required(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  dob: Yup.string().optional(),
  telephone: Yup.string().optional(),
  mobile: Yup.string().optional(),
  email: Yup.string().optional(),
})

const administerer = Yup.object({
  id: Yup.string().required(),
  role: Yup.string().optional(),
  email: Yup.string().optional(),
  friendlyName: Yup.string().optional(),
  firstName: Yup.string().optional().nullable(),
  lastName: Yup.string().optional().nullable(),
  lastLoginDate: Yup.string().nullable(),
  status: Yup.string().optional(),
})

const author = Yup.object({
  id: Yup.string().required(),
  craftId: Yup.string().optional(),
  role: Yup.string().optional(),
  active: Yup.string().optional(),
  friendlyName: Yup.string().optional(),
  firstName: Yup.string().optional().nullable(),
  lastName: Yup.string().optional().nullable(),
  lastLoginDate: Yup.string().optional().nullable(),
  status: Yup.string().optional(),
  timeout: Yup.number().optional(),
})

const journey = Yup.object().shape({
  id: Yup.string().optional(),
  arrivalDate: Yup.string().required(),
  returnDate: Yup.string().when('duration', {
    is: (duration) => !!!duration,
    then: (schema) => schema.required('Return Date is required if Length of Stay is empty'),
  }).nullable(),
  duration: Yup.object({
    id: Yup.string(),
    title: Yup.string(),
  }).when('returnDate', {
    is: (returnDate) => !!!returnDate,
    then: (schema) => schema.required('Length of Stay is required if Return Date is empty'),
  }).nullable(),
  trip: Yup.object({
    id: Yup.string().optional(),
    ownerId: Yup.string().optional(),
    title: Yup.string().optional(),
    dateCreated: Yup.string().optional(),
    dateUpdated: Yup.string().optional(),
  }).optional(),
  destination: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  accommodation: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
    code: Yup.string().optional(),
  }),
}, [['returnDate', 'duration']])

const trip = Yup.object({
  id: Yup.string().optional(),
  title: Yup.string().required(),
  companions: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      title: Yup.string().optional(),
    })
  ).min(1),
  purposes: Yup.array(
    Yup.object({
      id: Yup.string().required(),
      title: Yup.string().optional(),
    })
  ).min(1),
  journeys: Yup.array(journey),
})

const vaccine = Yup.object({
  id: Yup.number().optional(),
  number: Yup.number().min(1).required(),
  batchNumber: Yup.string().required(),
  prevaccinationchecksCompleted: Yup.number().required(),
  date: Yup.string().required(),
  type: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  drug: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
    vaccineTypeId: Yup.string().optional(),
  }),
  site: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  route: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  administerer,
  author,
  dateCreated: Yup.string().optional(),
})

const malariaMedicine = Yup.object({
  id: Yup.string().optional(),
  drug: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  quantity: Yup.number().min(1).required(),
  batchNumber: Yup.string().required(),
  dateAdministered: Yup.string().required(),
  administerer,
})

const test = Yup.object({
  id: Yup.string().optional(),
  date: Yup.string().required(),
  type: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  lab: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  administerer,
})

const note = Yup.object({
  id: Yup.string().optional(),
  note: Yup.string().optional(),
  author,
  travelHealthInfo: Yup.object({
    id: Yup.string().optional(),
    bite_avoidance: Yup.number().required(),
    personal_safety: Yup.number().required(),
    side_effects: Yup.number().required(),
    infectious_diseases: Yup.number().required(),
    insurance: Yup.number().required(),
    sun_environment: Yup.number().required(),
    food_water_hygiene: Yup.number().required(),
    emailed_info: Yup.number().required(),
  }),
  dateCreated: Yup.string().optional(),
  newInformation: Yup.boolean().test('New information', 'A note must contain some information.', function (val) {
    const { note, travelHealthInfo } = this.parent
    return (
      note ||
      Object.entries(travelHealthInfo)
        .map(([key, value]) => value)
        .filter(val => val).length > 0
    )
  }),
})

const appointment = Yup.object({
  id: Yup.string().optional(),
  startDate: Yup.string().required(),
  endDate: Yup.string().required(),
  title: Yup.string().optional(),
  patients: Yup.array(patient),
  room: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  type: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  status: Yup.object({
    id: Yup.string().required(),
    title: Yup.string().optional(),
  }),
  notes: Yup.array(
    Yup.object({
      room: Yup.object({
        id: Yup.string().optional(),
        note: Yup.string().optional(),
      }),
    })
  ),
})

const document = Yup.object({
  id: Yup.string().required(),
  assteId: Yup.string().required(),
  fileName: Yup.string().required(),
  fileSize: Yup.number().required(),
  lastModifiedDate: Yup.string().required(),
  url: Yup.string().required(),
  uploader: administerer.required(),
})

// ----------------------------------------- End Reusable

// ========================================= Begin Fields

const title = Yup.object({
  id: Yup.string(),
  title: Yup.string().optional(),
})

const nationality = Yup.object({
  id: Yup.string(),
  title: Yup.string().optional(),
})

const address = Yup.object({
  id: Yup.string().nullable(),
  addressLine1: Yup.string().nullable(),
  addressLine2: Yup.string().nullable(),
  townCity: Yup.string().nullable(),
  county: Yup.string().nullable(),
  postalCode: Yup.string().nullable(),
  resident: Yup.string().nullable(),
  country: Yup.object({
    id: Yup.string().required().nullable(),
    title: Yup.string().optional(),
  }).nullable(),
})

const medicalHistory = Yup.object({
  id: Yup.string().nullable(),
  ownerId: Yup.string().nullable(),
  respiratory_illness: Yup.number().required().nullable(),
  respiratory_illness_details: Yup.string().defined().optional(),
  heart_disease: Yup.number().required().nullable(),
  heart_disease_details: Yup.string().defined().optional(),
  diabetes_or_epilepsy: Yup.number().required().nullable(),
  diabetes_or_epilepsy_details: Yup.string().defined().optional(),
  gastro_liver_or_kidney: Yup.number().required().nullable(),
  gastro_liver_or_kidney_details: Yup.string().defined().optional(),
  immunosuppression: Yup.number().required().nullable(),
  immunosuppression_details: Yup.string().defined().optional(),
  mental_health: Yup.number().required().nullable(),
  mental_health_details: Yup.string().defined().optional(),
  other: Yup.number().required().nullable(),
  other_details: Yup.string().defined().optional(),
  breast_feeding_pregnancy: Yup.number().required().nullable(),
  breast_feeding_pregnancy_details: Yup.string().defined().optional(),
  allergies: Yup.number().required().nullable(),
  allergies_details: Yup.string().defined().optional(),
  medication: Yup.number().required().nullable(),
  medication_details: Yup.string().defined().optional(),
})

const preVaccinationChecks = Yup.object({
  id: Yup.string().nullable(),
  ownerId: Yup.string().nullable(),
  all_checked: Yup.number().required(),
  allergies: Yup.number().required(),
  allergies_details: Yup.string().optional(),
  live_vaccines: Yup.number().required(),
  live_vaccines_details: Yup.string().optional(),
  immunocompetent: Yup.number().required(),
  immunocompetent_details: Yup.string().optional(),
  pmh_meds_nad: Yup.number().required(),
  pmh_meds_nad_details: Yup.string().optional(),
  contraindications: Yup.number().required(),
  contraindications_details: Yup.string().optional(),
  childhood_vaccines: Yup.number().required(),
  childhood_vaccines_details: Yup.string().optional(),
  feeling_well: Yup.number().required(),
  feeling_well_details: Yup.string().optional(),
  yf_checklist_read: Yup.number().required(),
  yf_checklist_read_details: Yup.string().optional(),
})

// Contact validation
const contactValidation = Yup.boolean().test(
  'Contact Information',
  'A patient must have some contact information. Please enter an email address, mobile or telephone number',
  function (val) {
    const { email, telephone, mobile } = this.parent
    return email !== undefined || telephone !== undefined || mobile !== undefined
  }
)

// Main schema
const patientInfoSchema = Yup.object({
  id: Yup.string().required(),
  uid: Yup.string().optional(),
  firstName: Yup.string().required('New patients must have a first name.'),
  lastName: Yup.string().required('New patients must have a last name.'),
  dob: Yup.string().required('New patients must have a date of birth.'),
  email: Yup.string().email().optional(),
  mobile: Yup.string().optional(),
  telephone: Yup.string().optional(),
  title: title.nullable(),
  nationality: nationality.nullable(),
  address: address.required(),
  gpPractice: Yup.string().nullable(),
  consentToGDPR: Yup.boolean().optional(),
  consentParentGuardian: Yup.boolean().optional(),
  // PMH/MEDS
  medicalHistory: medicalHistory.required(),
  // Trips
  trips: Yup.array(trip).required(),
  // Vaccines
  vaccines: Yup.array(vaccine).required(),
  preVaccinationChecks: preVaccinationChecks.required(),
  // Prescriptions
  malariaMedicines: Yup.array(malariaMedicine).required(),
  // Tests
  tests: Yup.array(test).required(),
  // Notes
  notes: Yup.array(note).required(),
  // documents: Yup.array(document).required(),
  // appointments: Yup.array(appointment).required(),
  contactValidation,
})

export default patientInfoSchema
export type PatientInfoSchemaType = Yup.InferType<typeof patientInfoSchema>
