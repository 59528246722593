import { Routes, Route } from 'react-router-dom'
import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setAll } from 'redux/dataSlice'
import useAuth from 'hooks/useAuth'
import useApi from 'hooks/useApi'
import BaseLayout from 'layouts/BaseLayout'
import DashboardLayout from 'layouts/DashboardLayout'
import LoadingCover from 'components/LoadingCover'
import LoginView from 'views/LoginView'
import CalendarView from 'views/CalendarView'
import CalendarView2 from 'views/CalendarView2'
import useMountEffect from 'hooks/useMountEffect'
import 'scss/base.scss'

const App = () => {
  const { getUser, setUser, user } = useAuth()
  const api = useApi()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)

  const appInitialised = useRef(false)

  // 1. Check auth
  useMountEffect(() => {
    // Ensure only initialises once
    // React strict mode mounts app twice
    if (appInitialised.current) return
    appInitialised.current = true

    const initApp = async () => {
      const authCheck = await getUser?.()
      if (authCheck.data) {
        // Set user data
        setUser?.(authCheck.data)
      } else {
        // If not authorised, re-route to login screen
        // TODO: do something with the error
        navigate('/login', { replace: true })
        setLoading(false)
      }
    }

    initApp()
  }, [])

  // 2. Load data
  // Can only be done after user is logged in
  // Re runs when user changes from null
  useEffect(() => {
    if (user !== null) {
      setLoading(true)

      const loadData = async () => {
        try {
          const dataResponse = await api.data.all()
          if (dataResponse.errors) {
            // TODO: do something with errors
            // maybe throw errors for error boundary to catch?
          } else {
            dispatch(setAll(dataResponse.data))
          }
          navigate('/')
        } catch (e) {
          console.error('App load error:', e)
        } finally {
          setLoading(false)
        }
      }

      loadData()

      const interval = setInterval(() => {
        api.session.poll()
      }, 3600000 - 60000)

      return () => clearInterval(interval)
    }
  }, [user])

  return (
    <>
      {loading ? (
        <LoadingCover text="Loading..." />
      ) : (
        <Routes>
          <Route path="/" element={<BaseLayout />}>
            <Route path="login" element={<LoginView />} />
            <Route element={<DashboardLayout />}>
              <Route index element={<CalendarView />} />
              <Route path="testing" element={<CalendarView2 />} />
            </Route>
          </Route>
        </Routes>
      )}
    </>
  )
}

export default App
