import useApi from 'hooks/useApi'
import { useState, useEffect } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'

import { useIdleTimer } from 'react-idle-timer'

import useAuth from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'

type IdleManagerState = 'active' | 'idle'

const IdleManager = () => {
  const { session } = useApi()
  const { user } = useAuth()
  const navigate = useNavigate()

  // Timeout values
  // NOTE: Source from current user - default 20 minutes in milliseconds
  const idleTimeoutMs = (user?.timeout || 1200) * 1000
  // NOTE: Prompt 60 seconds BEFORE user session expiries
  const promptTimeoutMs = 60_000

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState<IdleManagerState>('active')
  const [remaining, setRemaining] = useState(idleTimeoutMs)
  const [promptOpen, setPromptOpen] = useState(false)

  const onIdle = () => {
    setState('idle')
    // NOTE: Craft user session has now expired - redirect to the login page
    navigate('/login', { replace: true })
  }

  const onActive = () => {
    setState('active')
    setPromptOpen(false)
  }

  const onAction = () => {
    if (!promptOpen) {
      session.poll()
    }
  }

  const onPrompt = () => {
    setPromptOpen(true)
  }

  const { activate, getRemainingTime } = useIdleTimer({
    onAction,
    onIdle,
    onActive,
    onPrompt,
    timeout: idleTimeoutMs,
    promptBeforeIdle: promptTimeoutMs,
    throttle: 60_000,
  })

  const handleStillHere = () => {
    setLoading(true)
    activate()
    session.poll()
    setLoading(false)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Modal show={promptOpen} centered>
      <Modal.Body>
        <div className="mb-4">You will be signed out in {remaining} {remaining != 1 ? 'seconds' : 'second'}.</div>
        <div className="d-flex justify-content-start align-items-center gap-4 mt-4">
          <Button disabled={loading} variant="danger" className="text-white" onClick={() => handleStillHere()}>
            {loading ? (
              <span className="d-flex align-items-center gap-3">
                <Spinner size="sm" />
                Processing...
              </span>
            ) : (
              <>Keep me signed in</>
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default IdleManager
