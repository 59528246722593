import { Spinner } from "react-bootstrap"
import 'scss/components/loading-cover.scss'

const LoadingCover = ({ text }) => {
  return (
    <div className="loading-cover">
      <Spinner animation="border"/>
      { text }
    </div>
  )
}

export default LoadingCover