import { Edit } from 'components/Icons'
import { useFormikContext } from 'formik'
import useModal from 'hooks/useModal'
import { Button, Spinner } from 'react-bootstrap'

const EditButtons = ({ dirty, isEditing, isSubmitting, handleSubmit, setIsEditing, handleReset, valid }) => {
  const { values } = useFormikContext<Patient>()

  const { openModal: openDeletePatient, isLoading: isDeletingPatient } = useModal('DELETE_PATIENT')

  return (
    <div className="edit-controls d-flex gap-2">
      {!isEditing ? (
        <Button
          disabled={isSubmitting}
          size="sm"
          onClick={() => {
            setIsEditing(true)
          }}
        >
          Update <Edit />
        </Button>
      ) : (
        <>
          <Button
            disabled={isSubmitting}
            size="sm"
            variant="danger"
            className="text-white"
            onClick={() => {
              setIsEditing(false)
              handleReset()
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!dirty || !valid || isSubmitting}
            size="sm"
            type="submit"
            onClick={() => {
              handleSubmit()
            }}
            variant="success"
            className="text-white"
          >
            {isSubmitting ? (
              <>
                Saving changes... <Spinner size="sm" className="ms-2" />
              </>
            ) : (
              'Save changes'
            )}
          </Button>
        </>
      )}
      <Button
        variant="danger"
        size="sm"
        disabled={isSubmitting || isDeletingPatient}
        className="text-white ms-5"
        onClick={e => openDeletePatient({ patient: values })}
      >
        Delete patient
      </Button>
    </div>
  )
}

export default EditButtons
