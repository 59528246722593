import { Button, ButtonGroup } from 'react-bootstrap'
import { Cross } from 'components/Icons'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'redux/store'
import { useEffect, useState } from 'react'
import useApi from 'hooks/useApi'
import { removeActivePatient, setDisplayingPatientId } from 'redux/stateSlice'

const ActivePatientsFooter = () => {
  const dispatch = useDispatch()
  const api = useApi()

  const [ isLoading, setIsLoading ] = useState(true)
  const [ activePatients, setActivePatients ] = useState<BasicPatient[]>([])

  const { activePatientIds, displayingPatientId } = useSelector((state: RootState) => ({
    activePatientIds: state.state.activePatientIds,
    displayingPatientId: state.state.displayingPatientId
  }))

  const loadPatientsDetails = async () => {
    setIsLoading(true)

    if (activePatientIds.length) {
      const _patientDetails = await api.patients.ids(activePatientIds)

      if (_patientDetails.data) {
        setActivePatients([..._patientDetails.data])
      }
    }

    setIsLoading(false)
  }
  const handleRemovePatient = (e, id) => {
    e.stopPropagation()
    dispatch(removeActivePatient(id))
  }

  useEffect(() => {
    if (activePatientIds.length) {
      loadPatientsDetails()
    } else {
      // Ensure empty
      setActivePatients([])
    }
  }, [activePatientIds])

  return (
    activePatients.length ?
      <footer id="active-patients" className="shadow-sm bg-white position-fixed bottom-0 w-100 d-flex justify-content-end p-2">
        <ButtonGroup size="sm">
          {activePatients.map((patient) => (
            <Button
              key={patient.id}
              onClick={() => dispatch(setDisplayingPatientId(patient.id))}
              variant={displayingPatientId === patient.id ? "primary" : "outline-primary"}
              disabled={isLoading}>
              {[patient.firstName, patient.lastName].join(" ")}
              { displayingPatientId !== patient.id && (
                <Cross size={"1rem"} onClick={(e) => handleRemovePatient(e, patient.id) }></Cross>)
              }
            </Button>
          ))}
        </ButtonGroup>
      </footer> :
      <></>
  )
}

export default ActivePatientsFooter
