import { Button, Form, Table } from 'react-bootstrap'
import { useFormikContext, FieldArray, Field } from 'formik'
import ClientSearch from './ClientSearch'
import { Danger, Tick, Cross } from './Icons'
import moment from 'moment'
import RemoveButton from './RemoveButton'
import { type AppointmentSchemaType } from 'schemas/appointment-schema'

interface PatientRowProps {
  index: number
  onRemove: () => void
  isNew?: boolean
}

const PatientRow: React.FC<PatientRowProps> = ({ index, onRemove, isNew }) => {
  const { values, setFieldValue } = useFormikContext<AppointmentSchemaType>()
  const prefix = isNew ? 'newPatients' : 'existingPatients'

  return (
    <tr>
      <td>
        {isNew ? (
          <span className="text-danger">
            <Cross size={25} />
          </span>
        ) : (
          <span className="text-success">
            <Tick size={25} />
          </span>
        )}
      </td>
      <td>
        <Field disabled={!isNew} as={Form.Control} name={`${prefix}[${index}].firstName`} />
      </td>
      <td>
        <Field disabled={!isNew} as={Form.Control} name={`${prefix}[${index}].lastName`} />
      </td>
      <td>
        <Field
          disabled={!isNew}
          as={Form.Control}
          type="date"
          value={moment(values[prefix][index].dob).format('YYYY-MM-DD')}
          name={`${prefix}[${index}].dob`}
        />
      </td>
      <td>
        <Field disabled={!isNew} as={Form.Control} type="email" name={`${prefix}[${index}].email`} />
      </td>
      <td>
        <Field disabled={!isNew} as={Form.Control} type="tel" name={`${prefix}[${index}].mobile`} />
      </td>
      <td>
        <Field disabled={!isNew} as={Form.Control} type="tel" name={`${prefix}[${index}].telephone`} />
      </td>
      <td>
        {isNew ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100 position-relative">
            <Field
              as={Form.Check}
              size="lg"
              checked={values.newPatients[index].sendRegistrationEmail == 1}
              value=""
              onChange={e => setFieldValue(`newPatients[${index}].sendRegistrationEmail`, Number(e.target.checked))}
            />
          </div>
        ) : (
          ''
        )}
      </td>
      <td>
      {isNew ? (
          <div className="d-flex align-items-center justify-content-center w-100 h-100 position-relative">
            <Field
              as={Form.Check}
              size="lg"
              checked={values.newPatients[index].isTravelling == 1}
              value=""
              onChange={e => setFieldValue(`newPatients[${index}].isTravelling`, Number(e.target.checked))}
            />
          </div>
        ) : (
          ''
        )}
      </td>
      <td>
        <RemoveButton onClick={onRemove} />
      </td>
    </tr>
  )
}

const AppointmentPatients = ({setHasAcknowledgedConfirmation} : {setHasAcknowledgedConfirmation?: React.Dispatch<React.SetStateAction<boolean | null>>}) => {
  const { values } = useFormikContext<AppointmentSchemaType>()

  return (
    <div className="">
      <div className="mb-5 d-flex justify-content-between align-items-center flex-wrap">
        <h4 className="mb-0 d-flex align-items-center gap-3">
          <span>Patients</span>
          {!values.existingPatients.length && !values.newPatients.length ? (
            <span className="opacity-50 fs-5 text-danger">
              <Danger />
            </span>
          ) : (
            ''
          )}
        </h4>
        <div className="d-flex gap-3 flex-fill justify-content-between">
          <FieldArray name="existingPatients">
            {({ push: addExistingPatient }) => (
              <ClientSearch
                containerProps={{ style: { marginLeft: '2rem', width: '100%', maxWidth: '400px' } }}
                onResultClick={patient => {
                  if (
                    !values.existingPatients.filter(existingPatient => existingPatient.id === patient.id.toString())
                      .length
                  ) {
                    addExistingPatient({
                      id: patient.id,
                      firstName: patient.firstName,
                      lastName: patient.lastName,
                      dob: patient.dob,
                      email: patient.email,
                      mobile: patient.mobile,
                      telephone: patient.telephone,
                    })
                  }
                }}
                placeholder="Add existing patient"
              />
            )}
          </FieldArray>
          <FieldArray name="newPatients">
            {({ push: addNewPatient }) => (
              <Button
                onClick={() => {

                  addNewPatient({
                    firstName: '',
                    lastName: '',
                    dob: moment(new Date().toISOString()).format('YYYY-MM-DD'),
                    email: '',
                    telephone: '',
                    mobile: '',
                    isTravelling: 1,
                    sendRegistrationEmail: 1,
                  })
                  if (setHasAcknowledgedConfirmation) {
                    setHasAcknowledgedConfirmation(null)
                  }
                }
                }
              >
                Add new patient
              </Button>
            )}
          </FieldArray>
        </div>
      </div>
      {values.existingPatients.length || values.newPatients.length ? (
        <Table responsive>
          <thead className="bg-secondary text-white">
            <tr>
              {['', 'First name', 'Last name', 'Date of Birth', 'Email', 'Mobile', 'Telephone', 'Send Email?', 'Travelling?', ''].map(
                (header, index) => (
                  <th style={{ whiteSpace: 'nowrap' }} key={index}>
                    {header}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            <FieldArray name="existingPatients">
              {({ remove: removeExistingPatient }) => {
                return (
                  <>
                    {values.existingPatients.map((patient, index) => {
                      return <PatientRow key={index} index={index} onRemove={() => removeExistingPatient(index)} />
                    })}
                  </>
                )
              }}
            </FieldArray>
            <FieldArray name="newPatients">
              {({ remove: removeNewPatient }) => {
                return (
                  <>
                    {values.newPatients.map((patient, index) => {
                      return <PatientRow key={index} index={index} onRemove={() => removeNewPatient(index)} isNew />
                    })}
                  </>
                )
              }}
            </FieldArray>
          </tbody>
        </Table>
      ) : (
        ''
      )}
    </div>
  )
}

export default AppointmentPatients
