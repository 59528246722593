import { useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import { Field, useFormikContext } from 'formik'
import TextArea from 'components/TextArea'
import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import RemoveButton from 'components/RemoveButton'
import useCanEdit from 'hooks/useCanEdit'

const travelHealthOptions = [
  { key: 'bite_avoidance', label: 'Bite avoidance' },
  { key: 'emailed_info', label: 'Emailed info' },
  { key: 'food_water_hygiene', label: 'Food & water' },
  { key: 'infectious_diseases', label: 'Infectious diseases' },
  { key: 'insurance', label: 'Insurance' },
  { key: 'personal_safety', label: 'Personal Safety' },
  { key: 'side_effects', label: 'Side effects' },
  { key: 'sun_environment', label: 'Sun & environment' },
]

const NotesTable = ({ handleRemove, isEditing }) => {
  const { values, isSubmitting, setFieldValue } = useFormikContext<Patient>()

  const [infoCounter, setInfoCounter] = useState(0)

  const canEdit = useCanEdit()

  const tableData = useMemo(
    () =>
      [...values.notes].map(note => ({
        dateCreated: new Date(note.dateCreated),
        note: note.note,
        addedBy: {
          id: note.author.id,
          title: `${note.author.firstName || ''} ${note.author.lastName || ''}`.trim() || note.author.friendlyName,
        },
        travelHealthInfo: note.travelHealthInfo,
      })),
    [values]
  )

  const columnHelper = createColumnHelper<(typeof tableData)[number]>()

  const columns = useMemo(
    () => [
      columnHelper.accessor('note', {
        header: 'Note',
        cell: info => {
          return (
            <Field
              disabled={
                !isEditing ||
                !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
                isSubmitting
              }
              name={`notes[${info.row.index}].note`}
              rows={5}
              as={TextArea}
            />
          )
        },
        meta: {
          style: {
            width: '99%',
          },
        },
        enableSorting: false,
      }),
      columnHelper.accessor('travelHealthInfo', {
        header: 'Advice Given',
        cell: info => {
          return (
            <div style={{ width: '175px' }}>
              {travelHealthOptions.map(option => {
                const checked = Boolean(values.notes[info.row.index].travelHealthInfo[option.key])
                return (
                  <Field
                    key={option.key}
                    as={Form.Check}
                    disabled={
                      !isEditing ||
                      !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
                      isSubmitting
                    }
                    size="sm"
                    id={`notes:${info.row.index}:travelHealthInfo:${option.key}`}
                    label={option.label}
                    checked={checked}
                    onChange={e => {
                      setInfoCounter(prev => prev + 1)
                      setFieldValue(`notes[${info.row.index}].travelHealthInfo[${option.key}]`, Number(!checked))
                    }}
                  />
                )
              })}
            </div>
          )
        },
      }),
      columnHelper.accessor('dateCreated', {
        header: 'Date created',
        cell: info => {
          return (
            <Field
              as={Form.Control}
              type="datetime-local"
              step="1"
              name={`notes[${info.row.index}].dateCreated`}
              disabled
            />
          )
        },
      }),
      columnHelper.accessor('addedBy', {
        header: 'Created by',
        cell: info => {
          return <Form.Control
            readOnly
            value={info.getValue().title}
            disabled
            style={{ minWidth: 150 }} />
        },
      }),
      columnHelper.display({
        id: 'remove',
        header: '',
        cell: info =>
          isEditing &&
          canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) &&
          (
            <RemoveButton
              type="button"
              disabled={isSubmitting}
              onClick={() => handleRemove(info.row.index)} />
          ),
      }),
    ],
    [values.notes.length, infoCounter, isEditing, isSubmitting]
  )

  return (
    <FunctionalTable
      data={tableData}
      columns={columns}
      options={{ className: 'notes-table' }}
      sortingState={[{ id: 'dateCreated', desc: false }]}
    />
  )
}

export default NotesTable
