import { PropsWithChildren } from 'react'
import { AppModal } from 'redux/modalSlice'
import { Modal, CloseButton, Button, Spinner } from 'react-bootstrap'
import useModal from 'hooks/useModal'

interface CustomModalProps extends PropsWithChildren {
  name: AppModal
  action: () => Promise<void>
  title: string
  actionText: string
  actionDisabled?: boolean
}

const CustomModal = (props: CustomModalProps) => {
  const { isOpen, closeModal, isLoading, setLoading } = useModal(props.name)
  return (
    <Modal show={isOpen} centered onHide={() => closeModal()}>
      <Modal.Header>
        <h4>{props.title}</h4>
        <CloseButton variant="white" onClick={closeModal} />
      </Modal.Header>
      <Modal.Body>
        {props.children}
        <div className="d-flex justify-content-start align-items-center gap-4 mt-4">
          <Button disabled={isLoading} onClick={closeModal}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || props.actionDisabled}
            variant="danger"
            className="text-white"
            onClick={async () => {
              try {
                setLoading(true)
                await props.action()
              } finally {
                setLoading(false)
              }
            }}
          >
            {isLoading ? (
              <span className="d-flex align-items-center gap-3">
                <Spinner size="sm" />
                Processing...
              </span>
            ) : (
              <>{props.actionText}</>
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CustomModal
