import React from 'react'
import useAuth from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'

const LogoutButton = ({ children }: { children: React.ReactNode }) => {
  const auth = useAuth()
  const navigate = useNavigate()

  return React.cloneElement(children as React.ReactElement<any>, {
    onClick: async () => {
      const logoutRequest = await auth.logout()
      if (!logoutRequest.data) {
        // TODO: handle errors
        return
      }
      navigate('/login')
    },
  })
}

export default LogoutButton
