import { PropsWithChildren } from 'react'
import { User } from 'components/Icons'
import 'scss/components/patient-tag.scss'

interface Props<C extends React.ElementType> extends PropsWithChildren {
  as?: C
  label: string
}

type GenericProps<C extends React.ElementType> = Props<C> & Omit<React.ComponentPropsWithoutRef<C>, keyof Props<C>>

const PatientTag = <C extends React.ElementType>({ as, label, children, ...props }: GenericProps<C>) => {
  let Type = as || 'span'
  let classNames = [props.className, 'patient-tag'].join(' ')

  return (
    <Type {...props} className={classNames}>
      <User />
      {label}
      {children}
    </Type>
  )
}

export default PatientTag
