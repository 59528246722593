import { EventExtendedProps, NurseExtendedProps } from './CalendarWidget'
import { EventContentArg } from '@fullcalendar/core'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import appointmentPopover from './AppointmentPopover'
import { setDisplayingPatientId } from 'redux/stateSlice'
import useData from 'hooks/useData'

const EventBlock = ({ event }: { event: EventContentArg }) => {
  const extendedProps = event.event.extendedProps
  const [showPopover, setShowPopover] = useState(false)
  const dispatch = useDispatch()
  const data = useData()

  if (event.event.allDay) {
    // Event is a staff event
    const { userId } = extendedProps as NurseExtendedProps

    const nurse = data.nurses.one(userId)

    return (
      <div
        style={{
          background: '#cccccc',
          color: '#333333',
          padding: '0 5px',
        }}
      >
        {nurse?.title}
      </div>
    )
  } else {
    // Event is an appointment
    const { typeId, statusId, patients } = extendedProps as EventExtendedProps

    const handleEventPatientClick = id => dispatch(setDisplayingPatientId(id))

    return (
      <OverlayTrigger show={showPopover} overlay={appointmentPopover(event, handleEventPatientClick, setShowPopover)}>
        <div
          className="event-block"
          data-type={typeId}
          data-status={statusId}
          onMouseEnter={() => setShowPopover(true)}
          onMouseLeave={() => setShowPopover(false)}
        >
          <div className="event-type"></div>
          <div className="event-block-inner">
            <span className="event-title">
              {patients.map(patient => `${patient.firstName} ${patient.lastName}`).join(', ')}
            </span>
          </div>
        </div>
      </OverlayTrigger>
    )
  }
}

export default EventBlock
