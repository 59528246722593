import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'redux/store'
import { AppModal, open, close, setParams, setLoading } from 'redux/modalSlice'

const useModal = <TModal extends AppModal>(modal: TModal) => {
  const modalState = useSelector((state: RootState) => state.modals[modal])
  const dispatch = useDispatch()

  const openModal = (props?: typeof modalState['props']) => {
    dispatch(
      setParams({
        name: modal,
        props,
      })
    )
    dispatch(open(modal))
  }

  const closeModal = () => {
    dispatch(close())
  }

  const _setLoading = (loading: boolean) => {
    dispatch(setLoading({ name: modal, loading }))
  }

  return {
    ...modalState,
    openModal,
    closeModal,
    setLoading: _setLoading,
  }
}

export default useModal
