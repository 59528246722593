import { useEffect, useState } from 'react'
import { Offcanvas, Row, Col, CloseButton } from 'react-bootstrap'
import { RootState } from 'redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { setEditAppointmentId } from 'redux/stateSlice'
import { updateLoadedAppointment } from 'redux/calendarSlice'
import { Formik, FormikHelpers } from 'formik'
import { getNextFreeSlotFromDate } from 'helpers/date'
import useApi from 'hooks/useApi'
import useModal from 'hooks/useModal'
import useToast from 'hooks/useToast'
import useHelpers from 'hooks/useHelpers'
import useAvailability from 'hooks/useAvailability'
import LoadingCover from 'components/LoadingCover'
import AvailabilityGrid from 'components/AvailabilityGrid'
import AppointmentSummary from 'components/AppointmentSummary'
import AppointmentPatients from 'components/AppointmentPatients'
import appointmentEditSchema from 'schemas/appointment-edit-schema'
import { AppointmentSchemaType } from 'schemas/appointment-schema'
import NewPatientConfirmationModal from 'components/NewPatientConfirmationModal'
/**
 * Edit appointments offcanvas
 */
const EditAppointment: React.FC = () => {
  const { editAppointmentId } = useSelector((state: RootState) => ({
    editAppointmentId: state.state.editAppointmentId,
  }))

  const dispatch = useDispatch()
  const api = useApi()
  const toast = useToast()
  const helpers = useHelpers()

  const [isLoading, setIsLoading] = useState(false)
  const [isEditingDate, setIsEditingDate] = useState(false)
  const [editingDate, setEditingDate] = useState(getNextFreeSlotFromDate(new Date()).toISOString())

  const { openModal } = useModal('DELETE_APPOINTMENT')

  const { isCalculating, timeSlots, availability, loadAvailability } = useAvailability(editingDate)

  const [initialValues, setInitialValues] = useState<AppointmentSchemaType>({
    intent: 'edit',
    startDate: '',
    endDate: '',
    existingPatients: [],
    newPatients: [],
    roomId: '',
    statusId: '',
    title: '',
    typeId: '',
    id: '',
    notes: [],
  })

  const [hasAcknowledgedConfirmation, setHasAcknowledgedConfirmation] = useState<boolean | null>(null)

  const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
    if (values.intent === 'create') {
      return
    }

    if (values.newPatients.length > 0 && !hasAcknowledgedConfirmation) {
      let areAnyNewPatientsTravelling = false
      values.newPatients.forEach((newPatient) => {
        if (newPatient.isTravelling && newPatient.sendRegistrationEmail) {
          areAnyNewPatientsTravelling = true
        }
      })
      
      if (areAnyNewPatientsTravelling) {
        setHasAcknowledgedConfirmation(false)
        return
      }
    }

    const { setSubmitting, resetForm } = formikHelpers

    if (values) {
      const saveRequest = await api.appointments.save(helpers.appointments.formatEditSchemaForRequest(values))

      if (saveRequest.errors) {
        toast.error({
          title: 'Error saving appointment',
          text: saveRequest.errors[0].body,
        })
        setSubmitting(false)
        return
      }

      toast.success({
        title: 'Appointments',
        text: `Successfully saved appointment at ${new Date(values.startDate).toDateString()}`,
        timestamp: true,
      })

      // Update appointment info to latest
      resetForm({ values: helpers.appointments.appointmentToFormValues(saveRequest.data) })

      const { startDate, endDate } = saveRequest.data
      setEditingDate(startDate)
      setIsEditingDate(false)

      // Update calendar appointment to latest if appointment is in loaded range
      if (helpers.appointments.isInLoadedRange(startDate, endDate)) {
        dispatch(updateLoadedAppointment(saveRequest.data))
      }

      setSubmitting(false)

      handleClose()
    }
  }

  // Sets the initialValues to the editing appointment details
  useEffect(() => {
    const loadAppointmentInfo = async (editAppointmentId: number) => {
      setIsLoading(true)
      const appointmentRequest = await api.appointments.one(editAppointmentId)
      if (appointmentRequest.errors) {
        // TODO: do something with errors
        setIsLoading(false)
        return
      }
      setInitialValues(helpers.appointments.appointmentToFormValues(appointmentRequest.data))
      setEditingDate(appointmentRequest.data.startDate)
      setIsLoading(false)
    }

    if (editAppointmentId) {
      loadAppointmentInfo(editAppointmentId)
    }
  }, [editAppointmentId])

  // Refreshes availability when editing date is changed or user start editing the date
  useEffect(() => {
    if (isEditingDate) {
      loadAvailability(initialValues)
    }
  }, [isEditingDate, editingDate])

  const handleClose = () => {
    dispatch(setEditAppointmentId(null))
  }

  return (
    <>
      <Offcanvas
        show={editAppointmentId !== null}
        onHide={handleClose}
        onExited={() => setIsEditingDate(false)}
        placement="end"
        backdropClassName="new-appointment-backdrop"
        className="new-appointment-offcanvas"
      >
        {isLoading ? (
          <LoadingCover text="Loading appointment..." />
        ) : (
          <>
            <Offcanvas.Header className="border-bottom border-2">
              <h3 className="mb-0">Edit Appointment</h3>
              <CloseButton onClick={handleClose} />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={appointmentEditSchema}
                validateOnMount={true}
                enableReinitialize={true}
              >
                {({ setFieldValue, initialValues: initialFormValues }) => {
                  return (
                    <>
                      <Row>
                        <Col xs={12}>
                          <AppointmentPatients setHasAcknowledgedConfirmation={setHasAcknowledgedConfirmation} />
                        </Col>
                      </Row>
                      <hr className="my-5" />
                      <Row>
                        <AvailabilityGrid
                          isEditingDate={isEditingDate}
                          setIsEditingDate={setIsEditingDate}
                          onCancel={() => {
                            if (initialFormValues.intent === 'create') {
                              return
                            }
                            setFieldValue('startDate', initialFormValues.startDate)
                            setFieldValue('endDate', initialFormValues.endDate)
                            setFieldValue('roomId', initialFormValues.roomId)
                          }}
                          timeSlots={timeSlots}
                          availability={availability}
                          isCalculating={isCalculating}
                          onDateChange={val => setEditingDate(val)}
                        />
                      </Row>
                      <hr className="my-5" />
                      <Row>
                        <Col>
                          <AppointmentSummary
                            appointmentId={editAppointmentId as number}
                            handleDelete={() =>
                              {
                                if (initialValues.intent === 'create') {
                                  return
                                }
                                openModal({
                                  appointmentId: editAppointmentId as number,
                                  initialStart: initialValues.startDate,
                                  initialEnd: initialValues.endDate,
                                })
                              }
                            }
                          />
                        </Col>
                      </Row>
                      <NewPatientConfirmationModal hasAcknowledgedConfirmation={hasAcknowledgedConfirmation} setHasAcknowledgedConfirmation={setHasAcknowledgedConfirmation} />
                    </>
                  )
                }}
              </Formik>
            </Offcanvas.Body>
          </>
        )}
      </Offcanvas>
    </>
  )
}

export default EditAppointment
