import { useEffect, useRef } from 'react'

const useMountEffect = (cb: React.EffectCallback, deps?: React.DependencyList) => {
  const mounted = useRef(false)

  useEffect(() => {
    if (mounted.current) return

    const returnVal = cb()

    mounted.current = true

    if (returnVal) {
      return returnVal
    }
  }, deps)
}
export default useMountEffect
