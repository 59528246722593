import { useContext } from 'react'
import { authContext } from 'providers/AuthProvider'

const useAuth = () => {
  const context = useContext(authContext)
  if (!context) throw new Error('Auth context can only be used within the <AuthProvider/>')
  return context
}

export default useAuth
