import { LayoutGroup } from 'framer-motion'
import { Form, Badge, Button } from 'react-bootstrap'

const FilterButton = ({ category, activeCategoryArray, onFilterClick }) => {
  return (
    <div className="mb-2 w-100">
      <Form.Check
        value={category.id}
        checked={activeCategoryArray.includes(category.id)}
        onChange={() => onFilterClick(category.id)}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        label={
          <Badge as="span" className={`bg-${category.value}`}>
            {category.title}
          </Badge>
        }
        type="switch"
        reverse
        id={`${category.title}:${category.id}`}
      />
    </div>
  )
}

const FilterGroup = ({ title, categories, activeCategoryArray, onFilterClick, onAllClick }) => {
  const allActive = activeCategoryArray.length === categories.length

  return (
    <div className="mb-5">
      <h5 className="mb-4">{title}</h5>
      <ul className="d-flex justify-content-start align-items-start flex-column gap-2 list-unstyled">
        <LayoutGroup>
          {categories.map((category, index) => {
            return (
              <FilterButton
                activeCategoryArray={activeCategoryArray}
                onFilterClick={onFilterClick}
                category={category}
                key={index}
              />
            )
          })}
        </LayoutGroup>
        <li className="mt-4">
          <Button className="" variant="transparent" role="button" onClick={onAllClick}>
            {allActive ? 'Uncheck all' : 'Check all'}
          </Button>
        </li>
      </ul>
    </div>
  )
}

export default FilterGroup
