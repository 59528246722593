import NavigationBar from 'components/layout/NavigationBar'
import { Outlet, useLocation } from 'react-router-dom'
import ViewWrapper from 'components/layout/ViewWrapper'
import { AnimatePresence } from 'framer-motion'
import NewAppointment from 'components/offcanvas/NewAppointment'
import PatientInfo from 'components/offcanvas/patient-info/PatientInfo'
import EditAppointment from 'components/offcanvas/EditAppointment'
import AppointmentsProvider from 'providers/AppointmentsProvider'
import ModalRenderer from 'components/modals/ModalRenderer'
import ActivePatientsFooter from 'components/ActivePatientsFooter'

const DashboardLayout = () => {
  const location = useLocation()

  return (
    <AppointmentsProvider>
      <NavigationBar />
      <AnimatePresence mode="wait">
        <ViewWrapper key={location.pathname}>
          <Outlet />
        </ViewWrapper>
      </AnimatePresence>

      <ActivePatientsFooter />
      <NewAppointment />
      <EditAppointment />
      <PatientInfo />
      <ModalRenderer />
    </AppointmentsProvider>
  )
}

export default DashboardLayout
