import CustomModal from './CustomModal'
import { useDispatch, useSelector } from 'react-redux'
import useModal from 'hooks/useModal'
import useApi from 'hooks/useApi'
import useToast from 'hooks/useToast'
import { RootState } from 'redux/store'
import { setLoadedAppointments } from 'redux/calendarSlice'
import { setDisplayingPatientId } from 'redux/stateSlice'

interface DeletePatientProps {
  patient: Patient
}

const DeletePatient = ({ patient }: DeletePatientProps) => {
  const toast = useToast()
  const api = useApi()
  const dispatch = useDispatch()
  const { closeModal, setLoading } = useModal('DELETE_PATIENT')

  const loadedAppointments = useSelector((state: RootState) => state.calendar.loadedAppointments)

  const handleDeletePatient = async () => {
    const deleteRequest = await api.patients.delete(patient.id)
    if (deleteRequest.errors) {
      // TODO: do something with errors
      setLoading(false)
      return
    }

    dispatch(setDisplayingPatientId(null))

    toast.success({
      title: 'Patients',
      text: 'Successfully deleted patient.',
      timestamp: true,
    })

    const deletedId = patient.id

    const _newAppointments = [...loadedAppointments]

    _newAppointments.forEach((appointment, index) => {
      const action = appointment.patients.map(patient => patient.id).includes(deletedId)

      if (action) {
        const _newPatients = [...appointment.patients].filter(patient => patient.id !== deletedId)

        if (_newPatients.length) {
          _newAppointments[index] = { ...appointment, patients: _newPatients }
        } else {
          _newAppointments.splice(index, 1)
        }
      }
    })

    dispatch(setLoadedAppointments(_newAppointments))

    closeModal()
  }

  return (
    <CustomModal name="DELETE_PATIENT" action={handleDeletePatient} title="Delete Patient" actionText="Delete">
      <p>
        Are you sure you want to delete{' '}
        {patient !== undefined && (
          <strong>
            {patient.firstName} {patient.lastName}?
          </strong>
        )}
        <br />
        Doing so will remove this patient from any related appointments and delete appointments in which they are the
        sole patient.
      </p>
      <small>
        <strong>You cannot undo this change.</strong>
      </small>
    </CustomModal>
  )
}

export default DeletePatient
