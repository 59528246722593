import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'
import { camelCase } from 'lodash'

interface DataSliceState extends AppData {
  appointmentTypes: { id: number; title: string; value: string }[]
  appointmentStatuses: { id: number; title: string; value: string }[]
}

const initialState: DataSliceState = {
  appointmentTypes: [],
  appointmentStatuses: [],
  rooms: [],
  nurses: [],
  countries: [],
  destinations: [],
  nationalities: [],
  drugs: [],
  accommodations: [],
  testTypes: [],
  journeyDurations: [],
  labs: [],
  titles: [],
  tripPurposes: [],
  tripCompanions: [],
  vaccineRoutes: [],
  vaccineTypes: [],
  vaccineSites: [],
}

const dataSlice = createSlice({
  name: 'data',
  initialState: initialState,
  reducers: {
    setAll: (state, action: PayloadAction<AppData>) => {
      // Add value prop to types and statuses
      // For use with bootstrap theme colour classes (e.g. 'bg-toBeConfirmed')
      const filters = {
        appointmentTypes: action.payload.appointmentTypes.map(type => ({ ...type, value: camelCase(type.title) })),
        appointmentStatuses: action.payload.appointmentStatuses.map(status => ({
          ...status,
          value: camelCase(status.title),
        })),
      }
      // Returning an objects sets the entire state object
      return { ...action.payload, ...filters }
    },
  },
})

export const { setAll } = dataSlice.actions

export default dataSlice.reducer
