import { Plus, Email } from 'components/Icons'
import TextArea from 'components/TextArea'
import { Field, FieldArray, useFormikContext } from 'formik'
import useAuth from 'hooks/useAuth'
import { Button, Col, Collapse, FloatingLabel, Form, Row, Spinner } from 'react-bootstrap'
import FormSection from '../FormSection'
import { PatientTabProps } from '../PatientInfo'
import VaccinesTable from '../tables/VaccinesTable'
import moment from 'moment'
import useHelpers from 'hooks/useHelpers'
import useApi from 'hooks/useApi'
import useToast from 'hooks/useToast'
import { useState } from 'react'

import 'scss/components/pre-vaccination-checklist.scss'
import { PatientInfoSchemaType } from 'schemas/patient-info-schema'

type ChecklistItemProps = {
  title: string
  itemKey: string
  isEditing: boolean
  isSubmitting: boolean
}

const ChecklistItem = ({ title, itemKey, isEditing, isSubmitting }: ChecklistItemProps) => {
  const { values, setFieldValue } = useFormikContext<PatientInfoSchemaType>()
  const [open, setOpen] = useState(false)

  const checked = values.preVaccinationChecks[itemKey]
  const hasContent = !!values.preVaccinationChecks[`${itemKey}_detail`]

  return (
    <div className="d-flex flex-column">
      <div className={`field-check-row mb-3 d-flex align-items-center gap-2 ${hasContent ? 'has-content' : ''}`}>
        <Field
          disabled={!isEditing || isSubmitting}
          as={Form.Check}
          label={title}
          checked={checked}
          id={itemKey}
          value={values.preVaccinationChecks[itemKey]}
          onChange={e => setFieldValue(`preVaccinationChecks[${itemKey}]`, Number(!parseInt(e.target.value)))}
          className="mb-0"
        />
        <button
          className={`action-button btn btn-outline-primary btn-sm ${open ? 'visible' : ''}`}
          type="button"
          onClick={open ? () => setOpen(false) : () => setOpen(true)}
          disabled={!hasContent && !open && !isEditing}
        >
          {!hasContent && !open ? '+ Add' : open ? '- Hide' : '+ Show'} comment
        </button>
      </div>
      <Collapse in={open}>
        <FloatingLabel label="More Information">
          <Field
            disabled={!isEditing || isSubmitting}
            as={TextArea}
            rows={4}
            name={`preVaccinationChecks[${itemKey}_detail]`}
          />
          <div className="mb-4" />
        </FloatingLabel>
      </Collapse>
    </div>
  )
}

const VaccinesTab: React.FC<PatientTabProps> = ({ isEditing }) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<Patient>()
  const { user } = useAuth()
  const helpers = useHelpers()
  const api = useApi()
  const toast = useToast()

  const [isSending, setIsSending] = useState(false)

  const [checklist] = useState([
    { title: 'Allergies', key: 'allergies' },
    { title: 'Live vaccines in last 4 weeks', key: 'live_vaccines' },
    { title: 'Immunosuppressed', key: 'immunocompetent' },
    { title: 'PMH / MEDS', key: 'pmh_meds_nad' },
    { title: 'Contraindications', key: 'contraindications' },
    { title: 'Childhood vaccines Not UTD', key: 'childhood_vaccines' },
    { title: 'Feeling unwell / pyrexial', key: 'feeling_well' },
    { title: 'YF Checklist read', key: 'yf_checklist_read' },
  ])
  const [allChecked] = useState([{ title: 'All checked/assessed NAD', key: 'all_checked' }])

  const newVaccine = {
    date: moment().format('YYYY-MM-DD'),
    batchNumber: '',
    prevaccinationchecksCompleted: 0,
    type: {
      id: null,
    },
    site: {
      id: null,
    },
    route: {
      id: null,
    },
    drug: {
      id: null,
    },
    administerer: {
      id: user?.id && helpers.nurses.isNurse(user.id) ? user.id : null,
    },
    number: 1,
    author: user
  }

  return (
    <div className="w-100">
      <Row className="g-5">
        <Col className="d-flex align-items-center justify-content-between">
          <h5>Vaccines</h5>
          {values.email ? (
            <Button
              disabled={isSending}
              className="d-flex gap-2 align-items-center"
              onClick={async () => {
                setIsSending(true)
                const historyRequest = await api.patients.vaccineHistory(values.id)

                if (historyRequest.data?.notifications.length == 0) {
                  toast.error({
                    title: 'Error sending vaccine history',
                    text: '',
                  })
                  setIsSending(false)
                  return
                }

                const recipients = [...new Set(historyRequest.data?.notifications.map(n => n.recipients).flat())]
                toast.success({
                  title: 'Notification Successful',
                  text: `Successfully sent vaccine history to ${recipients.join(', ')}`,
                })
                setIsSending(false)
              }}
            >
              {isSending ? (
                <>
                  Sending Vaccine History...
                  <Spinner size="sm" />
                </>
              ) : (
                <>
                  Email Vaccine History
                  <Email fontSize="1.5em" />
                </>
              )}
            </Button>
          ) : (
            ''
          )}
        </Col>
        <Col xs={12}>
          <FieldArray name="vaccines">
            {arrayHelpers => (
              <>
                <VaccinesTable
                  isEditing={isEditing}
                  handleAdd={arrayHelpers.insert}
                  handleRemove={arrayHelpers.remove}
                  handleReplace={arrayHelpers.replace}
                />
                <div className="d-flex justify-content-center align-item-center py-4">
                  <Button
                    size="sm"
                    disabled={!isEditing || isSubmitting}
                    onClick={() => {
                      arrayHelpers.push(newVaccine)
                    }}
                  >
                    Add a vaccine <Plus />
                  </Button>
                </div>
              </>
            )}
          </FieldArray>
        </Col>

        <Col xs={12}>
          <FormSection title="Pre Vaccine Checklist">
            <div className="pre-vaccination-checklist d-flex flex-column gap-2">
              {allChecked.map(item => (
                <Field
                  disabled={!isEditing || isSubmitting}
                  as={Form.Check}
                  label={item.title}
                  checked={values.preVaccinationChecks[item.key]}
                  id={item.key}
                  key={item.key}
                  value={values.preVaccinationChecks[item.key]}
                  onChange={e => {
                    const value = Number(!parseInt(e.target.value))
                    setFieldValue(`preVaccinationChecks[${item.key}]`, value)

                    value &&
                      checklist.forEach(n => {
                        setFieldValue(`preVaccinationChecks[${n.key}]`, 0)
                      })
                  }}
                  className="mb-0"
                />
              ))}
              <hr className="mb-3" />
              {checklist.map(item => {
                return (
                  <ChecklistItem
                    key={item.key}
                    itemKey={item.key}
                    title={item.title}
                    isEditing={isEditing}
                    isSubmitting={isSubmitting}
                  />
                )
              })}
            </div>
          </FormSection>
        </Col>
      </Row>
    </div>
  )
}

export default VaccinesTab
