import { useState, createContext } from 'react'
import useFetch from 'hooks/useFetch'

export interface User {
  id: number
  email: string
  friendlyName: string
  firstName: any
  lastName: any
  role: string
  status: string
  timeout: number
}

const useAuthHelpers = () => {
  const [user, setUser] = useState<User | null>(null)
  const { getRequest, postRequest } = useFetch()

  return {
    user,
    setUser,
    getUser: async () => {
      return await getRequest<User>(`api/auth`)
    },

    login: async (loginDetails: string) => {
      return await postRequest<User>('api/login', loginDetails)
    },
    logout: async (callback?: Function) => {
      const req = getRequest<never>('api/logout')
      callback && callback()
      return req
    },
  }
}

interface AuthContext extends ReturnType<typeof useAuthHelpers> {}
export const authContext = createContext<AuthContext | null>(null)

/**
 * The main Auth Provider.
 * Contains all methods and properties surrounding authorisation into the app
 */
export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const context = useAuthHelpers()
  return <authContext.Provider value={context}>{children}</authContext.Provider>
}
