import { EventContentArg } from '@fullcalendar/core'
import PatientTag from 'components/PatientTag'
import { Button, Popover, Table } from 'react-bootstrap'
import { EventExtendedProps } from './CalendarWidget'

const appointmentPopover = (eventInfo: EventContentArg, handleClick: (id: number) => void, setShow) => {
  const { patients } = eventInfo.event.extendedProps as EventExtendedProps

  return (
    <Popover
      id={`event-overlay-${eventInfo.event.id}`}
      className="shadow"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Popover.Header>
        <div className="d-flex justify-content-between">
          <strong>{eventInfo.timeText}</strong>
          <small>{eventInfo.event.start?.toLocaleDateString()}</small>
        </div>
      </Popover.Header>
      <div>
        <Table className="m-0 h-100">
          <tbody>
            {patients.map((patient, index) => {
              const noBorder = index === patients.length - 1
              return (
                <tr key={index}>
                  <td className={`${noBorder ? 'border-bottom-0' : ''}`}>
                    <PatientTag
                      onClick={() => handleClick(patient.id)}
                      as={Button}
                      size="sm"
                      variant="outline-secondary"
                      label={patient.firstName + ' ' + patient.lastName}
                    />
                  </td>
                  <td className={`${noBorder ? 'border-bottom-0' : ''}`}>
                    <div className="h-100 d-flex align-items-center">
                      <a href={`tel:${patient.telephone}`}>{patient.telephone}</a>
                    </div>
                  </td>
                  <td className={`${noBorder ? 'border-bottom-0' : ''}`}>
                    <div className="h-100 d-flex align-items-center">
                      <a href={`mailto:${patient.email}`}>{patient.email}</a>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </Popover>
  )
}

export default appointmentPopover
