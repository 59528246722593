import React, { PropsWithChildren } from 'react'
import { Button } from 'react-bootstrap'

interface ErrorBoundaryProps extends PropsWithChildren {
  fallback: (error, errorInfo) => JSX.Element
}

interface ErrorBoundaryState {
  error: Error | null
  errorInfo: React.ErrorInfo | null
}

/**
 * Custom error boundary component for now, until sentry integration
 */
class CustomErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
    errorInfo: null,
  }

  // Catch errors and set state
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error: error, errorInfo: errorInfo })
  }

  render() {
    const { error } = this.state
    if (error) {
      return (
        <div className="app-error-container">
          <div className="app-error-message">
            <h1 className="mb-4 text-danger">An error occurred.</h1>
            {process.env.NODE_ENV !== 'production' ? (
              <>
                <p>{error.message}</p>
                <p>{error.stack}</p>
                <div className="mt-5">
                  <Button onClick={() => window.location.reload()}>Try again</Button>
                </div>
              </>
            ) : (
              <p>The team have been notified and are currently working on a fix.</p>
            )}
          </div>
        </div>
      )
    } else {
      return <>{this.props.children}</>
    }
  }
}

export default CustomErrorBoundary
