import CustomToast from 'components/CustomToast'
import { LayoutGroup } from 'framer-motion'
import { ToastContainer } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/store'

/**
 * Isolates the useSelector hook so that getting state from the root store
 * doesn't affect other components in the app and force unneccesary re-renders
 */
const CustomToastContainer = () => {
  const { toasts } = useSelector((state: RootState) => state.state)

  return (
    <ToastContainer position="bottom-end" className="position-fixed p-3">
      <LayoutGroup>
        {toasts.map((toast, index) => {
          if (toast !== null)
            return <CustomToast key={index} toastKey={index} data={toast} />
          return ''
        })}
      </LayoutGroup>
    </ToastContainer>
  )
}

export default CustomToastContainer
