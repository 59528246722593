import CalendarWidget from 'components/calendar/CalendarWidget'
import { RootState } from 'redux/store'
import { useSelector } from 'react-redux'
import { useAppointmentsContext } from 'providers/AppointmentsProvider'
import { setVisibleDateRange } from 'redux/calendarSlice'
import { useDispatch } from 'react-redux'
import LoadingCover from 'components/LoadingCover'
import CalendarSidebar from 'components/sidebar/CalendarSidebar'
import 'scss/components/sidebar-panel.scss'

const CalendarView = () => {
  const { loading } = useAppointmentsContext()
  const dispatch = useDispatch()

  // State
  const { filteredAppointments, nurseAvailability } = useSelector((state: RootState) => ({
    filteredAppointments: state.calendar.filteredAppointments,
    nurseAvailability: state.calendar.nurseAvailability,
  }))

  // Update visible date range on interaction
  const handleChangeDateRange = (newStart: string, newEnd: string) => {
    dispatch(
      setVisibleDateRange({
        start: newStart,
        end: newEnd,
      })
    )
  }

  return (
    <>
      {loading ? <LoadingCover text="Loading appointments..." /> : ''}
      <CalendarSidebar />
      <div className="view-content position-relative">
        <CalendarWidget
          appointments={filteredAppointments}
          nurseAvailability={nurseAvailability}
          handleChangeDateRange={handleChangeDateRange}
        />
      </div>
    </>
  )
}

export default CalendarView
