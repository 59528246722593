import { Plus } from 'components/Icons'
import { FieldArray, useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { PatientTabProps } from '../PatientInfo'
import TestsTable from '../tables/TestsTable'
import useAuth from 'hooks/useAuth'
import moment from 'moment'
import useHelpers from 'hooks/useHelpers'

const PrescriptionsTab: React.FC<PatientTabProps> = ({ isEditing }) => {
  const { handleChange, isSubmitting } = useFormikContext<Patient>()
  const { user } = useAuth()
  const helpers = useHelpers()

  return (
    <div className="w-100">
      <FieldArray name="tests">
        {arrayHelpers => {
          return (
            <>
              <TestsTable
                isEditing={isEditing}
                handleAdd={arrayHelpers.insert}
                handleRemove={arrayHelpers.remove}
                handleReplace={arrayHelpers.replace}
                handleChange={handleChange}
              />
              <div className="d-flex justify-content-center align-item-center py-4">
                <Button
                  size="sm"
                  disabled={!isEditing || isSubmitting}
                  onClick={() => {
                    arrayHelpers.push({
                      date: moment().format('YYYY-MM-DD'),
                      administerer: {
                        id: user?.id && helpers.nurses.isNurse(user.id) ? user.id : null,
                      },
                      lab: {
                        id: null,
                      },
                      type: {
                        id: null,
                      },
                    })
                  }}
                >
                  Add test <Plus />
                </Button>
              </div>
            </>
          )
        }}
      </FieldArray>
    </div>
  )
}

export default PrescriptionsTab
