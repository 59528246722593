import { createColumnHelper } from '@tanstack/react-table'
import FunctionalTable from 'components/FunctionalTable'
import { Field, useFormikContext } from 'formik'
import { useMemo, useState } from 'react'
import { Form } from 'react-bootstrap'
import useData from 'hooks/useData'
import RemoveButton from 'components/RemoveButton'
import useHelpers from 'hooks/useHelpers'
import useCanEdit from 'hooks/useCanEdit'

/**
 * The table inside the vaccines tab, ideally should be a separate component to deal
 * with the arrayHelpers passed down from Formik's <FieldArray> component
 */
const VaccinesTable = ({ isEditing, handleAdd, handleRemove, handleReplace }) => {
  const { values, isSubmitting, handleChange, setFieldValue } = useFormikContext<Patient>()
  const data = useData()
  const helpers = useHelpers()
  const [typeCounter, setTypeCounter] = useState(0)

  const canEdit = useCanEdit()

  const { vaccineTypes, vaccineRoutes, vaccineSites, nurses } = {
    vaccineTypes: data.vaccineTypes.all(),
    vaccineRoutes: data.vaccineRoutes.all(),
    vaccineSites: data.vaccineSites.all(),
    nurses: data.nurses.all(),
  }

  const tableData = useMemo(
    () =>
      [...values.vaccines].map(vaccine => ({
        date: new Date(vaccine.date),
        type: vaccine.type.title || '',
        drug: vaccine.drug?.title || '',
        site: vaccine.site.title || '',
        route: vaccine.route.title || '',
        number: vaccine.number,
        batchNumber: vaccine.batchNumber,
        prevaccinationchecksCompleted: vaccine.prevaccinationchecksCompleted,
        administeredBy: `${data.nurses.one(vaccine.administerer.id)?.title}`,
        addedBy: {
          id: vaccine.author.id,
          title: `${vaccine.author.firstName || ''} ${vaccine.author.lastName || ''}`.trim() || vaccine.author.friendlyName,
        },
        dateCreated: new Date(vaccine.dateCreated),
      })),
    [values]
  )

  const columnHelper = createColumnHelper<(typeof tableData)[number]>()

  const tableColumns = useMemo(
    () => [
      columnHelper.accessor('date', {
        header: 'Date',
        cell: info => (
          <Field
            disabled={
              !isEditing ||
              !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
              isSubmitting
            }
            as={Form.Control}
            type="date"
            name={`vaccines[${info.row.index}].date`}
          />
        ),
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        cell: info => (
          <Field
            disabled={
              !isEditing ||
              !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
              isSubmitting
            }
            as={Form.Select}
            name={`vaccines[${info.row.index}].type.id`}
            onChange={e => {
              handleChange(e)
              const newVaccineType = e.target.value

              const newlyValidVaccineSubtypes = helpers.data.getVaccineSubTypes(newVaccineType)
              let newVaccineSubType = values.vaccines[info.row.index].drug.id
              if (newlyValidVaccineSubtypes.length > 0) {
                newVaccineSubType = newlyValidVaccineSubtypes[0].id
              }
              setFieldValue(`vaccines[${info.row.index}].type.id`, newVaccineType)
              setFieldValue(`vaccines[${info.row.index}].drug.id`, newVaccineSubType)
              setTypeCounter(prev => prev + 1)
            }}
          >
            <option value="default" selected disabled>
              Please select
            </option>
            {vaccineTypes.map(type => (
              <option key={type.id} value={type.id}>
                {type.title}
              </option>
            ))}
          </Field>
        ),
      }),

      columnHelper.accessor('drug', {
        header: 'Drug',
        cell: info => (
          <Field
            disabled={
              !isEditing ||
              !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
              isSubmitting
            }
            as={Form.Select}
            name={`vaccines[${info.row.index}].drug.id`}>
            <option value="default" selected disabled>
              Please select
            </option>
            {helpers.data.getVaccineSubTypes(values.vaccines[info.row.index].type.id).map(subType => (
              <option key={subType.id} value={subType.id}>
                {subType.title}
              </option>
            ))}
          </Field>
        ),
      }),
      columnHelper.accessor('site', {
        header: 'Site',
        cell: info => (
          <Field
            disabled={
              !isEditing ||
              !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
              isSubmitting
            }
            as={Form.Select}
            name={`vaccines[${info.row.index}].site.id`}>
            <option value="default" selected disabled>
              Please select
            </option>
            {vaccineSites.map(site => (
              <option key={site.id} value={site.id}>
                {site.title}
              </option>
            ))}
          </Field>
        ),
      }),
      columnHelper.accessor('route', {
        header: 'Route',
        cell: info => (
          <Field
            disabled={
              !isEditing ||
              !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
              isSubmitting
            }
            as={Form.Select}
            name={`vaccines[${info.row.index}].route.id`}>
            <option value="default" selected disabled>
              Please select
            </option>
            {vaccineRoutes.map(route => (
              <option key={route.id} value={route.id}>
                {route.title}
              </option>
            ))}
          </Field>
        ),
      }),
      columnHelper.accessor('number', {
        header: 'No.',
        cell: info => (
          <Field
            type="number"
            min="1"
            // NOTE: Updated to allow more digits to be seen
            max="99999"
            disabled={
              !isEditing ||
              !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
              isSubmitting
            }
            as={Form.Control}
            name={`vaccines[${info.row.index}].number`} // TODO: check this
          />
        ),
      }),
      columnHelper.accessor('batchNumber', {
        header: 'Batch no.',
        cell: info => (
          <Field
            disabled={
              !isEditing ||
              !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
              isSubmitting
            }
            as={Form.Control}
            type="text"
            name={`vaccines[${info.row.index}].batchNumber`}
          />
        ),
      }),
      columnHelper.accessor('prevaccinationchecksCompleted', {
        header: 'PVC',
        cell: info => (
          <div className="d-flex align-items-center justify-content-center">
            <Field
              disabled={
                !isEditing ||
                !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
                isSubmitting
              }
              as={Form.Check}
              size="lg"
              checked={info.row.getValue('prevaccinationchecksCompleted') == 1}
              value=""
              onChange={e => setFieldValue(`vaccines[${info.row.index}].prevaccinationchecksCompleted`, Number(e.target.checked))}
            />
          </div>
        ),
      }),
      columnHelper.accessor('administeredBy', {
        header: 'Administered by',
        cell: info => (
          <Field
            // TODO: review UI for this? maybe something more contextual like a badge??
            as={Form.Select}
            name={`vaccines[${info.row.index}].administerer.id`}
            disabled={
              !isEditing ||
              !canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) ||
              isSubmitting
            }>
            <option value="default" selected disabled>
              Please select
            </option>
            {nurses.map(nurse => (
              <option key={nurse.id} value={nurse.id}>
                {nurse.title}
              </option>
            ))}
          </Field>
        ),
      }),
      columnHelper.display({
        id: 'remove',
        header: '',
        cell: info => (
          isEditing &&
          canEdit(info.row.original.addedBy.id, info.row.original.dateCreated) &&
          (
            <RemoveButton
              type="button"
              disabled={isSubmitting}
              onClick={() => handleRemove(info.row.index)}
            />
          )
        ),
      }),
    ],
    [values.vaccines.length, typeCounter, isEditing, !isEditing || isSubmitting]
  )

  return <FunctionalTable data={tableData} columns={tableColumns} />
}

export default VaccinesTable
