import { Row, Col, Button, Form, FloatingLabel, Spinner } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import { Formik, Form as FormikForm, Field, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { useState } from 'react'

const initialValues = {
  loginName: '',
  password: '',
  // NOTE: Use the short-duration sessions - 20 mins
  rememberMe: false,
  terms: false,
}

const validationSchema = Yup.object().shape({
  loginName: Yup.string()
    .required('')
    .test('is-email', 'Invalid email', value => {
      if (value) {
        return value.includes('@') ? Yup.string().email().isValidSync(value) : true
      }
      return true
    }),
  password: Yup.string().required(''),
  rememberMe: Yup.boolean().optional(),
  terms: Yup.bool().oneOf([true], 'To use this app, please accept the terms and conditions'),
})

type LoginValues = typeof initialValues

/**
 * Login Form component
 */
const LoginForm = () => {
  const auth = useAuth()
  const [invalidCredentials, setInvalidCredentials] = useState<string | undefined>()

  // Submit handler -- post to craft
  const handleSubmit = async (values: LoginValues, formikHelpers: FormikHelpers<LoginValues>) => {
    setInvalidCredentials(undefined)
    const { setSubmitting } = formikHelpers

    const loginRequest = await auth.login(
      JSON.stringify({
        loginName: values.loginName,
        password: values.password,
        rememberMe: values.rememberMe,
      })
    )

    if (loginRequest.data) {
      const user = loginRequest.data
      auth.setUser?.(user)
    } else {
      const error = loginRequest.errors[0]
      setInvalidCredentials(error.body)
    }

    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      initialTouched={{ terms: true }}
      validateOnMount={true}
    >
      {({ isSubmitting, isValid, errors, values, touched }) => {
        return (
          <FormikForm>
            <Row>
              <Col className="d-flex flex-column gap-4">
                <div
                  className="form-error"
                  style={{
                    visibility: invalidCredentials ? 'visible' : 'hidden',
                    margin: '0 0 -1rem 0',
                    padding: 0,
                    color: 'red',
                  }}
                >
                  <p>Invalid username or password</p>
                </div>

                <div className="form-field">
                  <FloatingLabel label="Username or Email Address">
                    <Field
                      disabled={isSubmitting}
                      as={Form.Control}
                      name="loginName"
                      placeholder="Username or Email Address"
                    />
                  </FloatingLabel>
                  {touched.loginName && errors.loginName && (
                    <div className="fs-sm text-danger field-error mt-1">{errors.loginName}</div>
                  )}
                </div>

                <div className="form-field">
                  <FloatingLabel label="Password">
                    <Field
                      disabled={isSubmitting}
                      as={Form.Control}
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </FloatingLabel>
                  {touched.password && errors.password && (
                    <div className="fs-sm text-danger field-error mt-1">{errors.password}</div>
                  )}
                </div>

                <div className="form-field">
                  <Field
                    disabled={isSubmitting}
                    as={Form.Check}
                    type="checkbox"
                    checked={values.terms}
                    label="I accept the Terms and Conditions"
                    name="terms"
                    id="login-field:terms"
                  />
                  {touched.terms && errors.terms && (
                    <div className="fs-sm text-danger field-error mt-1">{errors.terms}</div>
                  )}
                </div>

                <Button type="submit" disabled={isSubmitting || !isValid}>
                  {isSubmitting ? (
                    <>
                      <Spinner size="sm" /> Logging in...
                    </>
                  ) : (
                    'Log In'
                  )}
                </Button>
              </Col>
            </Row>
          </FormikForm>
        )
      }}
    </Formik>
  )
}

export default LoginForm
