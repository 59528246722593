import React, { useState } from 'react'
import FileUploader from 'components/FileUploader'
import { PatientTabProps } from '../PatientInfo'
import { Table, Button, Modal, CloseButton, Spinner, Badge } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { Trash, Open } from 'components/Icons'
import useApi from 'hooks/useApi'
import useToast from 'hooks/useToast'

const DocumentsTab: React.FC<PatientTabProps> = ({ patientDetails, isEditing }) => {
  const { initialValues, values, setFieldValue, resetForm } = useFormikContext<Patient>()
  const api = useApi()
  const toast = useToast()

  type Document = Patient['documents'][number]
  const [deletingDocument, setDeletingDocument] = useState<Document | undefined>()
  const [isDeleting, setIsDeleting] = useState(false)

  const refreshPatientDocuments = async () => {
    const patientDetailsRequest = await api.patients.one(patientDetails.id)
    if (patientDetailsRequest.data) {
      const newDocuments = patientDetailsRequest.data.documents
      resetForm({ values: { ...initialValues, documents: newDocuments } })
    }
  }

  const handleDelete = async (document: Document) => {
    setIsDeleting(true)
    const deleteRequest = await api.documents.delete(document.assetId)
    if (deleteRequest.data) {
      setDeletingDocument(undefined)
      // TODO: refresh patient data
      await refreshPatientDocuments()
      toast.success({
        title: 'Document deleted',
        text: `Successfully deleted ${document.fileName} for ${patientDetails.firstName} ${patientDetails.lastName}.`,
        timestamp: true,
      })
    }
    setIsDeleting(false)
  }

  return (
    <>
      <Modal bg="danger" centered show={deletingDocument !== undefined} onHide={() => setDeletingDocument(undefined)}>
        <Modal.Header className="text-white bg-danger">
          Delete document
          <CloseButton variant="white" onClick={() => setDeletingDocument(undefined)} />
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete document <span className="text-primary">{deletingDocument?.fileName}</span>?
          </p>
          <div className="d-flex justify-content-center align-items-center gap-4">
            <Button disabled={isDeleting} onClick={() => setDeletingDocument(undefined)}>
              Cancel
            </Button>
            <Button
              disabled={isDeleting}
              variant="danger"
              className="text-white"
              onClick={() => handleDelete(deletingDocument as Document)}
            >
              {isDeleting ? (
                <span className="d-flex align-items-center gap-3">
                  <Spinner size="sm" />
                  Deleting...
                </span>
              ) : (
                'Delete'
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <div className="w-100">
        <div className="mb-5">
          <h4 className="mb-4">Documents</h4>
          {values.documents.length ? (
            <Table hover>
              <thead className="bg-secondary text-white">
                <tr>
                  <th>Filename</th>
                  <th>Last modified</th>
                  <th>Uploaded by</th>
                  <th>Preview</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {values.documents.map(document => {
                  return (
                    <tr key={document.id}>
                      <td>
                        {document.fileName}{' '}
                        {!initialValues.documents.find(initialDocument => initialDocument.id === document.id) ? (
                          <Badge>new</Badge>
                        ) : (
                          ''
                        )}
                      </td>
                      <td>{document.lastModifiedDate}</td>
                      <td>{document.uploader?.friendlyName}</td>
                      <td>
                        <Button as="a" size="sm" href={document.url} target="_blank">
                          Open <Open />
                        </Button>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          variant="danger"
                          className="text-white"
                          onClick={() => setDeletingDocument(document)}
                        >
                          <Trash size={20} />
                        </Button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          ) : (
            <>
              No documents for {patientDetails?.firstName} {patientDetails?.lastName}
            </>
          )}
        </div>
        {patientDetails ? (
          <>
            <FileUploader patientId={patientDetails.id} onSuccess={refreshPatientDocuments} />
          </>
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default DocumentsTab
