import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'redux/store'
import {
  toggleTypeFilter,
  toggleStatusFilter,
  setAllActiveTypes,
  setAllActiveStatuses,
  unsetAllActiveTypes,
  unsetAllActiveStatuses,
} from 'redux/stateSlice'

const useAppointmentFilters = () => {
  const dispatch = useDispatch()

  const { appointmentTypes, appointmentStatuses, activeTypes, activeStatuses } = useSelector((state: RootState) => ({
    appointmentTypes: state.data.appointmentTypes,
    appointmentStatuses: state.data.appointmentStatuses,
    activeTypes: state.state.activeTypes,
    activeStatuses: state.state.activeStatuses,
  }))

  const toggleType = (id: number) => {
    dispatch(toggleTypeFilter(id))
  }

  const toggleStatus = (id: number) => {
    dispatch(toggleStatusFilter(id))
  }

  const toggleAllTypes = () => {
    if (activeTypes.length === appointmentTypes.length) dispatch(unsetAllActiveTypes())
    else dispatch(setAllActiveTypes([...appointmentTypes].map(type => type.id)))
  }

  const toggleAllStatuses = () => {
    if (activeStatuses.length === appointmentStatuses.length) dispatch(unsetAllActiveStatuses())
    else dispatch(setAllActiveStatuses([...appointmentStatuses].map(type => type.id)))
  }

  return {
    toggleType,
    toggleStatus,
    toggleAllTypes,
    toggleAllStatuses,
  }
}

export default useAppointmentFilters
