import { useState } from 'react'
import useApi from './useApi'
import useData from './useData'
import moment, { Moment } from 'moment'
import { AppointmentSchemaType } from 'schemas/appointment-schema'

export type AvailabilityRoom = {
  roomId: number
  roomTitle: string
  slots: {
    date: Date
    durations: number[]
  }[]
}

/**
 * Hook for finding availability throughout the app, only requires a search date to use
 */
const useAvailability = (date: string) => {
  const api = useApi()
  const data = useData()

  const [isCalculating, setIsCalculating] = useState(false)
  const [timeSlots, setTimeSlots] = useState<Date[]>([])
  const [availability, setAvailability] = useState<AvailabilityRoom[]>([])

  const selectedDate = moment(date)

  const { start: businessStart, end: businessEnd } = getBusinessHours(selectedDate)

  const _getTimeSlots = async () => {
    const timeSlots = await getTimeSlots(businessStart, businessEnd)
    setTimeSlots(timeSlots)

    return timeSlots
  }

  
  const _loadAvailability = async (appointment?: AppointmentSchemaType) => {
    setIsCalculating(true)
    
    await _getTimeSlots()

    const day = selectedDate.format('YYYY-MM-DD')
    const dayEnd = selectedDate.clone().add(1, 'day').format('YYYY-MM-DD')
    
    const appointments = await api.appointments.range(day, dayEnd) // all appointments for the day
    const roomAvailabilities = await api.roomAvailability.range(day, day) // all available rooms for the day
    
    
    if (appointments.errors || roomAvailabilities.errors) {
      setAvailability([])
      setIsCalculating(false)
      return
    }
    
    const rooms = data.rooms.all()

    const availability = await loadAvailability(selectedDate, appointments.data, roomAvailabilities.data, rooms, appointment)
    setAvailability(availability)
    setIsCalculating(false)
  }

  return {
    isCalculating,
    timeSlots,
    availability,
    loadAvailability: _loadAvailability,
  }
}

export const getBusinessHours = (date: Moment) => {
  const start = date.clone().hours(8).minutes(0).toDate()
  const end = date.clone().hours(21).minutes(0).toDate()
  return { start: start, end: end }
}

export const getLunchHours = (date: Moment) => {
  const start = date.clone().hours(12).minutes(30).seconds(0).toDate()
  const end = date.day() === 6 ? date.clone().hours(13).minutes(0).seconds(0).toDate() : date.clone().hours(13).minutes(15).seconds(0).toDate()

  return { start: start, end: end }
}

export const getTimeSlots = (startDate: Date, endDate: Date): Promise<Date[]> => {
  return new Promise(resolve => {
    const timeSlots: Date[] = []
    for (let iteratedDate = startDate; iteratedDate < endDate; iteratedDate.setMinutes(iteratedDate.getMinutes() + 15)) {
      timeSlots.push(new Date(iteratedDate))
    }
    resolve(timeSlots)
  })
}

/**
 * Get availability
 */
export const loadAvailability = async (date: Moment, appointments: Appointment[], roomAvailabilities: RoomAvailability[], rooms: Room[] , appointment?: AppointmentSchemaType) => {
  const { start: lunchStart, end: lunchEnd } = getLunchHours(date) 
  const { start: businessStart, end: businessEnd } = getBusinessHours(date)

  const updatedTimeSlots = await getTimeSlots(businessStart, businessEnd)

  const room = roomAvailabilities.length > 0 ? roomAvailabilities[0] : null
  if (!room || !room.roomIds) {
    return []
  }

  
  const availability = room.roomIds
    .map(roomId => {
      const roomAvailability: AvailabilityRoom = {
        roomId: roomId,
        roomTitle: rooms.find(_room => _room.id === roomId)?.title || '',
        slots: [],
      }

      // All appointments for the day assigned to this room
      let activeAppointments = appointments.filter(
        _appointment =>
          _appointment.room.id === roomId && (_appointment.status.id === 1 || _appointment.status.id === 2)
      )

      if (appointment) {
        activeAppointments = [...activeAppointments].filter(
          _appointment => _appointment.id !== parseInt(appointment.id as string)
        )
      }

      updatedTimeSlots.forEach(timeSlot => {
        let durations = [15, 30, 45, 60]

        const _slot = moment(timeSlot)

        durations = durations.filter(duration => {
          const _durationStart = _slot.clone().toDate()
          const _durationEnd = _slot
            .clone()
            .minutes(_slot.minutes() + duration)
            .toDate()

          // Check if conflicts with any appointments
          const conflictingAppointments = [...activeAppointments].filter(_appointment => {
            return (
              _durationStart < moment(_appointment.endDate).toDate() &&
              _durationEnd > moment(_appointment.startDate).toDate()
            )
          })

          // Check if in conflicts with lunch or after work hours
          const conflictsWithLunchHours = _durationStart < lunchEnd && _durationEnd > lunchStart
          const conflictsWithBusinessHours = _durationEnd > businessEnd

          const ok = !conflictingAppointments.length && !conflictsWithLunchHours && !conflictsWithBusinessHours

          return ok
        })

        if (durations.length) {
          roomAvailability.slots.push({
            date: timeSlot,
            durations: durations,
          })
        }
      })
      return roomAvailability
    })
    .sort((roomA, roomB) => roomA.roomId - roomB.roomId)

    return availability
}

export default useAvailability
