import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

// Modal Components
import DeleteAppointment from 'components/modals/DeleteAppointment'
import NurseAvailability from 'components/modals/NurseAvailability'
import PatientChanges from 'components/modals/PatientChanges'
import DeletePatient from 'components/modals/DeletePatient'

export const modals = {
  DELETE_APPOINTMENT: DeleteAppointment,
  NURSE_AVAILABILITY: NurseAvailability,
  UNSAVED_PATIENT_CHANGES: PatientChanges,
  DELETE_PATIENT: DeletePatient,
}

export type AppModal = keyof typeof modals

// Cast type here to avoid complex typescript code
const initialState = Object.keys(modals).reduce(
  (previous, current) => ({ ...previous, [current]: { isOpen: false, isLoading: false, props: null, options: {} } }),
  {}
) as {
  [key in AppModal]: {
    isOpen: boolean
    isLoading: boolean
    props: React.ComponentProps<(typeof modals)[key]> | null
  }
}

const modalSlice = createSlice({
  name: 'Modals',
  initialState: initialState,
  reducers: {
    open: (state, action: PayloadAction<AppModal>) => {
      Object.keys(state).forEach(key => {
        state[key].isOpen = false
      })
      state[action.payload].isOpen = true
    },
    close: state => {
      Object.keys(state).forEach(key => {
        state[key].isOpen = false
        state[key].loading = false
      })
    },
    setParams: (state, action: PayloadAction<{ name: AppModal; props: (typeof initialState)[AppModal]['props'] }>) => {
      const { name, props } = action.payload
      state[name].props = props
    },
    setLoading: (state, action: PayloadAction<{ name: AppModal; loading: boolean }>) => {
      const { name, loading } = action.payload
      state[name].isLoading = loading
    },
  },
})

export const { open, close, setParams, setLoading } = modalSlice.actions

export default modalSlice.reducer
