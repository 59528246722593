import { createContext, useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLoadedAppointments,
  setVisibleAppointments,
  setFilteredAppointments,
  setLoadedDateRange,
  setRoomAvailability,
  setNurseAvailability,
} from 'redux/calendarSlice'
import useApi from 'hooks/useApi'
import { RootState } from 'redux/store'
import useMountEffect from 'hooks/useMountEffect'

const useProviderMethods = () => {
  const api = useApi()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)

  /**
   * Gets availability for the loaded range and sets it into redux
   */
  const loadRoomAvailability = async (start: string, end: string) => {
    const availabilityRequest = await api.roomAvailability.range(start, end)
    if (availabilityRequest.data) {
      dispatch(setRoomAvailability(availabilityRequest.data))
    } else {
      // TODO: do something with error
    }
  }

  /**
   * Gets the nurse availability for the laded range and sets it into redux
   */
  const loadNurseAvailability = async (start: string, end: string) => {
    const availabilityRequest = await api.nurseAvailability.range(start, end)
    if (availabilityRequest.data) {
      dispatch(setNurseAvailability(availabilityRequest.data))
    } else {
      // TODO: do something with error
    }
  }

  /**
   * The function that actually loads appointments
   */
  const loadAppointments = async (start: string, end: string) => {
    const appointmentsRequest = await api.appointments.range(start, end)
    if (appointmentsRequest.data) {
      dispatch(setLoadedAppointments(appointmentsRequest.data))
    }
  }

  return {
    loading,
    setLoading,
    loadAppointments,
    loadRoomAvailability,
    loadNurseAvailability,
  }
}

interface AppointmentsContext {
  loading: boolean
}

const appointmentsContext = createContext<AppointmentsContext>({
  loading: true,
})
export const useAppointmentsContext = () => useContext(appointmentsContext)

const AppointmentsProvider = ({ children }) => {
  const { loading, setLoading, loadAppointments, loadRoomAvailability, loadNurseAvailability } = useProviderMethods()

  const dispatch = useDispatch()

  const {
    loadedStart,
    loadedEnd,
    visibleStart,
    visibleEnd,
    activeTypes,
    activeStatuses,
    loadedAppointments,
    visibleAppointments,
  } = useSelector((state: RootState) => ({
    loadedStart: state.calendar.loadedDateRange.start,
    loadedEnd: state.calendar.loadedDateRange.end,
    visibleStart: state.calendar.visibleDateRange.start,
    visibleEnd: state.calendar.visibleDateRange.end,
    loadedAppointments: state.calendar.loadedAppointments,
    visibleAppointments: state.calendar.visibleAppointments,
    activeTypes: state.state.activeTypes,
    activeStatuses: state.state.activeStatuses,
  }))

  const loadNewData = async (start: string, end: string) => {
    const dayStartDate = start.split('T')[0]
    const dayEndDate = end.split('T')[0]

    setLoading(true)
    await Promise.all([
      loadRoomAvailability(dayStartDate, dayEndDate),
      loadNurseAvailability(dayStartDate, dayEndDate),
      loadAppointments(dayStartDate, dayEndDate),
    ])
    setLoading(false)
  }

  /**
   * Reload appointments ?
   * - reload when visible date range is outside of the loaded date range
   */
  useEffect(() => {
    const start = visibleStart.split('T')[0]
    const end = visibleEnd.split('T')[0]
    if (new Date(visibleStart) < new Date(loadedStart) || new Date(visibleEnd) > new Date(loadedEnd)) {
      dispatch(
        setLoadedDateRange({
          start: visibleStart,
          end: visibleEnd,
        })
      )
      loadNewData(start, end)
    }
  }, [visibleStart, visibleEnd])

  /**
   * Refresh visible appointments
   * - update when loaded appointments change (i.e. adding appointments while in same view)
   * - update when user changes visible range
   */
  useEffect(() => {
    const _visibleAppointments = loadedAppointments.filter(
      appointment =>
        new Date(appointment.startDate) >= new Date(visibleStart) &&
        new Date(appointment.endDate) <= new Date(visibleEnd)
    )
    dispatch(setVisibleAppointments(_visibleAppointments))
  }, [loadedAppointments, visibleStart, visibleEnd])

  /**
   * Refresh filtered appointments
   */
  useEffect(() => {
    const _filteredAppointments = visibleAppointments.filter(
      appointment => activeTypes.includes(appointment.type.id) && activeStatuses.includes(appointment.status.id)
    )
    dispatch(setFilteredAppointments(_filteredAppointments))
  }, [visibleAppointments, activeTypes, activeStatuses])

  useMountEffect(() => {
    loadNewData(loadedStart, loadedEnd)
  }, [])

  return (
    <appointmentsContext.Provider
      value={{
        loading,
      }}
    >
      {children}
    </appointmentsContext.Provider>
  )
}

export default AppointmentsProvider
