import { configureStore } from '@reduxjs/toolkit'
import stateReducer from 'redux/stateSlice'
import dataReducer from 'redux/dataSlice'
import calendarSlice from './calendarSlice'
import modalSlice from './modalSlice'

export const store = configureStore({
  reducer: {
    state: stateReducer,
    data: dataReducer,
    calendar: calendarSlice,
    modals: modalSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
